import React from 'react';
import '../css/Loading.css';

const Loading: React.FC = () => {
  return (
    <div className="loading__overlay">
      <div className="loading__text">Momela</div>
    </div>
  );
};

export default Loading;
