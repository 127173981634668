import React, { createContext, useContext, useEffect, useState } from 'react';

interface SideBarContextType {
  isSideBarOpen: boolean;
  toggleSideBar: () => void;
  closeSideBar: () => void;
}

const SideBarContext = createContext<SideBarContextType | undefined>(undefined);

export const SideBarProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);

  useEffect(() => {}, []);

  const toggleSideBar = () => {
    setIsSideBarOpen(!isSideBarOpen);
  };

  const closeSideBar = () => {
    setIsSideBarOpen(false);
  };

  useEffect(() => {
    setIsSideBarOpen(false);
  }, []);

  return (
    <SideBarContext.Provider
      value={{ isSideBarOpen, toggleSideBar, closeSideBar }}
    >
      {children}
    </SideBarContext.Provider>
  );
};

export const useSideBar = (): SideBarContextType => {
  const context = useContext(SideBarContext);
  if (!context) {
    throw new Error('useSideBar must be used within an SideBarProvider');
  }
  return context;
};
