import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import '../css/Post.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as fasHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons';
import API from '../utils/API/API';
import AuthAPI from '../utils/API/AuthAPI';
import { useAuth } from '../utils/AuthContext';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ItemSlider from './ItemSlider';
import Loading from './Loading';
import PostImageSlider from './PostImageSlider';

interface Category {
  id: number;
  name: string;
}

interface Brand {
  id: number;
  name: string;
  url: string;
  icon: string | null;
}

interface Skelton {
  id: number;
  name: string;
}

interface Item {
  id: number;
  name: string;
  price: number;
  image: string;
  url: string;
  brand: Brand | null;
  categories: Category[];
}

interface ModelInfo {
  bio: string;
  height: number;
  skelton: Skelton | null;
  icon: string | null;
  instagram: string;
}

interface PostData {
  id: number;
  username: string;
  modelinfo: ModelInfo;
  images: string[];
  comment: string;
  items: Item[];
  favorite_num: number;
  created_at: string;
}

const Post: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [post, setPost] = useState<PostData | null>(null);
  const [isFavorite, setIsFavorite] = useState(false);
  const [relevantPosts, setRelevantPosts] = useState<PostData[]>([]);
  const { isAuthenticated, isStaffUser } = useAuth();
  const navigate = useNavigate();

  const loadRelevantPosts = useCallback(async () => {
    try {
      const response = await API().get(`api/posts-relevant/`, {
        params: { post_id: id },
      });
      setRelevantPosts(response.data.posts);
    } catch (error) {
      console.error('関連する投稿を読み込めませんでした', error);
    }
  }, [id]);

  useEffect(() => {
    const fetchPostData = async () => {
      try {
        const response = await API().get(`api/posts/${id}/`);
        const postData: PostData = response.data;
        setPost(postData);

        await loadRelevantPosts();
        setIsFavorite(
          await AuthAPI()
            .get('api/favorites/')
            .then((res) => res.data.posts.map((post: PostData) => post.id))
            .then((posts: number[]) => posts.includes(Number(id)))
            .catch(() => {
              console.error('could not get favorites');
              return false;
            }),
        );
      } catch (error) {
        console.error('could not get post', error);
      }
    };

    fetchPostData();
  }, [id, loadRelevantPosts]);

  const toggleFavorite = async () => {
    try {
      if (isFavorite) {
        await AuthAPI(navigate).delete(`api/favorites/`, {
          data: { post_id: id },
        });
        setIsFavorite(false);
      } else {
        await AuthAPI(navigate).post(`api/favorites/`, { post_id: id });
        setIsFavorite(true);
      }
    } catch (error) {
      console.error(
        `お気に入りの${isFavorite ? '削除' : '追加'}に失敗しました`,
        error,
      );
    }
  };

  if (!post) return <Loading />;

  return (
    <div className="post__container">
      {/* この下のdivにまとめてcssを指定するか検討中 */}
      <div className="post__sectionone">
        <div className="post__image-container">
          <PostImageSlider post={post} />
        </div>
        <div className="post__below">
          <div className="post__user-info">
            <a href={`/profile/${post.username}`}>
              <span className="post__user-name">@{post.username}</span>
            </a>
            <button
              className={`post__btn-favorite ${isFavorite ? 'post__btn-favorite--active' : ''}`}
              onClick={toggleFavorite}
            >
              <FontAwesomeIcon
                icon={isFavorite ? fasHeart : farHeart}
                style={{
                  color: isFavorite ? '#FF0000' : '#000000',
                }}
              />
            </button>
          </div>
          <p>{post.comment}</p>

          {post.items &&
            post.items.length > 0 &&
            post.items.reduce((acc: JSX.Element[], item) => {
              if (
                item.brand &&
                !acc.find((it) => it.props.children === item.brand!.name)
              ) {
                acc.push(
                  <a
                    key={item.id}
                    href={`/brand/${item.brand!.id}`}
                    className="post__category-link"
                  >
                    {item.brand!.name}
                  </a>,
                );
              }
              return acc;
            }, [])}
        </div>
      </div>
      {isAuthenticated && isStaffUser && (
        <div className="post__edit-section">
          <Link to={`/staff/edit-post/${id}`} className="post__edit-button">
            編集する
          </Link>
        </div>
      )}

      <div className="post__used-item">
        <h3 className="post__item-title">使用アイテム</h3>
        <ItemSlider items={post.items} />
      </div>

      <div className="post__same-item">
        <h3 className="post__same-item-title">おすすめの投稿</h3>
        <div className="post__relevant-posts">
          {relevantPosts &&
            relevantPosts.length > 0 &&
            relevantPosts.map((post) => (
              <a
                key={post.id}
                href={`/posts/${post.id}`}
                className="post__relevant-post"
              >
                <img
                  src={post.images[0]}
                  alt="関連投稿"
                  className="post__relevant-post-image"
                />
              </a>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Post;
