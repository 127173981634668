import React from 'react';
import '../css/GridPosts.css';
import { DEFAULT_MODELUSER_ICON } from '../utils/Constant';

interface Post {
  id: number;
  images: string[];
  username: string;
  modelinfo: {
    icon: string | null;
  };
}

interface GridPostsProps {
  posts: Post[];
}

const GridPosts: React.FC<GridPostsProps> = ({ posts }) => {
  return (
    <div className="gridposts__post-row">
      {posts.length > 0 ? (
        posts.map((post, index) => (
          <div key={`${post.id}-${index}`} className="gridposts__post-item">
            <div className="gridposts__post-card">
              <a href={`/posts/${post.id}`}>
                <div className="gridposts__post-image-wrapper">
                  <img
                    src={post.images[0]}
                    className="gridposts__post-image"
                    alt="Post"
                  />
                </div>
              </a>
            </div>
            <div className="gridposts__post-info">
              <div className="gridposts__user-info">
                <img
                  src={post.modelinfo.icon || DEFAULT_MODELUSER_ICON}
                  alt={post.username}
                  className="gridposts__user-icon"
                />
                <p className="gridposts__username">{post.username}</p>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p>No posts available.</p>
      )}
    </div>
  );
};

export default GridPosts;
