import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  clearTokens,
  refreshAccessToken,
  verifyAccessToken,
} from './TokenManager';
import AuthAPI from '../utils/API/AuthAPI';

interface AuthContextType {
  isAuthenticated: boolean | null;
  isModelUser: boolean | null;
  isStaffUser: boolean | null;
  updateAuthStatus: () => Promise<boolean>;
  removeAuthInfo: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [isModelUser, setIsModelUser] = useState<boolean | null>(null);
  const [isStaffUser, setIsStaffUser] = useState<boolean | null>(null);

  const updateAuthStatus = useCallback(async () => {
    const fetchModelStatus = async () => {
      try {
        const response = await AuthAPI().get('api/mypage/');
        setIsModelUser(!!response.data.model);
        setIsStaffUser(response.data.is_staff);
      } catch (error) {
        console.error('Failed to fetch model status', error);
        setIsModelUser(false);
        setIsStaffUser(false);
      }
    };

    const verified = await verifyAccessToken();
    if (verified) {
      setIsAuthenticated(true);
      fetchModelStatus();
      return true;
    } else {
      const token = await refreshAccessToken();
      setIsAuthenticated(!!token);
      if (token) {
        fetchModelStatus();
      }
      return !!token;
    }
  }, []);

  const removeAuthInfo = useCallback(() => {
    clearTokens();
    setIsAuthenticated(false);
    setIsModelUser(false);
    setIsStaffUser(false);
  }, []);

  useEffect(() => {
    updateAuthStatus();
  }, [updateAuthStatus]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isModelUser,
        isStaffUser,
        updateAuthStatus,
        removeAuthInfo,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
