import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../css/PrivacySection.css';
import API from '../utils/API/AuthAPI';
import { setAccessToken, setRefreshToken } from '../utils/TokenManager';

const ResetPasswordConfirm: React.FC = () => {
  const { uidb64, token } = useParams<{ uidb64: string; token: string }>();
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('');
  const [error, setError] = useState<string>('');

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (newPassword !== confirmNewPassword) {
      setError('New passwords do not match');
      return;
    }

    await API()
      .post(`api/user/reset/password/confirm/${uidb64}/${token}/`, {
        new_password: newPassword,
        re_new_password: confirmNewPassword,
      })
      .then((response) => {
        setRefreshToken(response.data.refresh_token);
        setAccessToken(response.data.access_token);
        navigate('/');
      })
      .catch((error) => {
        if (error.response.data.error === 'serialize_error') {
          setError('パスワードが一致しません');
        } else {
          setError('不適切なパスワードです');
        }
      });
  };

  return (
    <div className="privacy-section privacy-section--reset-password-confirm">
      <h1 className="privacy-section__title">パスワードをリセットする</h1>
      {error && <p className="privacy-section__error">{error}</p>}
      <form onSubmit={handleSubmit} className="privacy-section__form">
        <div className="privacy-section__form-group">
          <label htmlFor="newPassword" className="privacy-section__label">
            新しいパスワード
          </label>
          <input
            type="password"
            id="newPassword"
            className="privacy-section__input"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </div>
        <div className="privacy-section__form-group">
          <label
            htmlFor="confirmNewPassword"
            className="privacy-section__label"
          >
            新しいパスワードを確認する
          </label>
          <input
            type="password"
            id="confirmNewPassword"
            className="privacy-section__input"
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            required
          />
        </div>
        <p className="privacy-section__password-criteria">
          ・8文字以上で設定
          <br />
          ・アルファベットと数字と記号が使用可能
        </p>
        <button type="submit" className="privacy-section__submit-button">
          パスワードをリセットする
        </button>
      </form>
    </div>
  );
};

export default ResetPasswordConfirm;
