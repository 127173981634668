import React from 'react';
import { Link } from 'react-router-dom';
import '../css/AboutUs.css';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="privacy-policy">
      <h1 className="privacy-policy__title">プライバシーポリシー</h1>

      <div className="privacy-policy__section">
        <h2 className="privacy-policy__section-title">
          当サイトに掲載されている広告について
        </h2>
        <p className="privacy-policy__section-description">
          当サイトでは、第三者配信の広告サービス（Googleアドセンス、A8.net、Amazonアソシエイト、Canva、felmat、PRESCO、RENTRACKS、seedApp、TGアフィリエイト、アクセストレード、インフォトップ、バリューコマース、もしもアフィリエイト）を利用しています。
          このような広告配信事業者は、ユーザーの興味に応じた商品やサービスの広告を表示するため、当サイトや他サイトへのアクセスに関する情報
          『Cookie』(氏名、住所、メール アドレス、電話番号は含まれません)
          を使用することがあります。
          またGoogleアドセンスに関して、このプロセスの詳細やこのような情報が広告配信事業者に使用されないようにする方法については、
          <a
            href="https://policies.google.com/technologies/ads?gl=jp"
            target="_blank"
            rel="noopener noreferrer"
          >
            こちら
          </a>
          をご覧ください。
        </p>
        <ul className="privacy-policy__list">
          <li className="privacy-policy__list-item">
            Cookie等の利用者を識別するための識別子
          </li>
          <li className="privacy-policy__list-item">
            閲覧しているURLやサイトタイトル名の情報、閲覧時刻
          </li>
          <li className="privacy-policy__list-item">
            ブラウザやOS、端末の種別、画面サイズやIPアドレスなどの利用環境に関する情報
          </li>
        </ul>
      </div>

      <div className="privacy-policy__section">
        <h2 className="privacy-policy__section-title">
          Amazonアソシエイト・プログラムについて
        </h2>
        <p className="privacy-policy__section-description">
          当サイトは、amazon.co.jpを宣伝しリンクすることによって、サイトが紹介料を獲得できる手段を提供することを目的に設定されたアフィリエイト宣伝プログラムである「Amazonアソシエイト・プログラム」の参加者です。
          Amazonのアソシエイトとして、当サイトは適格販売により収入を得ています。
        </p>
      </div>

      <div className="privacy-policy__section">
        <h2 className="privacy-policy__section-title">
          当サイトが使用しているアクセス解析ツールについて
        </h2>
        <p className="privacy-policy__section-description">
          当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。
          このGoogleアナリティクスはトラフィックデータの収集のためにCookieを使用しています。
          このトラフィックデータは匿名で収集されており、個人を特定するものではありません。
          この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。
          この規約に関して、詳しくは
          <a
            href="https://policies.google.com/technologies/partner-sites"
            target="_blank"
            rel="noopener noreferrer"
          >
            こちら
          </a>
          をご覧ください。
        </p>
        <ul className="privacy-policy__list">
          <li className="privacy-policy__list-item">
            Cookie等の利用者を識別するための識別子
          </li>
          <li className="privacy-policy__list-item">
            閲覧しているURLやサイトタイトル名の情報、閲覧時刻
          </li>
          <li className="privacy-policy__list-item">
            ブラウザやOS、端末の種別、画面サイズやIPアドレスなどの利用環境に関する情報
          </li>
        </ul>
      </div>

      <div className="privacy-policy__section">
        <h2 className="privacy-policy__section-title">
          個人情報の利用目的について
        </h2>
        <p className="privacy-policy__section-description">
          当サイトでは、お問い合わせやコメント投稿の際に氏名・メールアドレス等の個人情報を入力いただく場合があります。
          取得した個人情報は、必要な連絡のみに利用させていただくもので、これらの目的以外では利用いたしません。
          また、取得した個人情報は適切に管理し、以下に該当する場合を除いて第三者に開示することはありません。
        </p>
        <ul className="privacy-policy__list">
          <li className="privacy-policy__list-item">
            本人の同意が得られた場合
          </li>
          <li className="privacy-policy__list-item">
            法令により開示が求められた場合
          </li>
        </ul>
      </div>

      <div className="privacy-policy__section">
        <h2 className="privacy-policy__section-title">免責事項</h2>
        <p className="privacy-policy__section-description">
          当サイトのコンテンツや情報において、可能な限り正確な情報を提供するよう努めておりますが、
          必ずしもその内容の正確性および完全性を保証するものではありません。
          当該情報に基づいて被ったいかなる損害について、一切責任を負うものではございませんのであらかじめご了承ください。
          また、当サイトから移動された先のホームページは、当サイトが管理、運営するものではありません。
          移動先サイトで提供される情報の真偽、サービス等につきましても一切の責任も負いかねますので、ご了承ください。
        </p>
      </div>

      <div className="privacy-policy__section">
        <h2 className="privacy-policy__section-title">肖像権について</h2>
        <p className="privacy-policy__section-description">
          当ブログは著作権や肖像権の侵害を目的としたものではありません。
          著作権や肖像権に関して問題がございましたら、
          <Link to="/contact">お問い合わせ</Link>
          よりご連絡ください。迅速に対応させていただきます。
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
