import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { LOGIN_PATH } from './Constant';
import { useAuth } from './AuthContext';
import Loading from '../components/Loading';

interface ProtectedRouteProps {
  element: JSX.Element;
  redirectTo?: string;
}

export const AuthenticatedRoute: React.FC<ProtectedRouteProps> = ({
  element,
  redirectTo = LOGIN_PATH,
}) => {
  const { isAuthenticated } = useAuth();

  const location = useLocation();

  if (isAuthenticated === null) {
    return <Loading />;
  }
  return isAuthenticated ? (
    element
  ) : (
    <Navigate to={redirectTo} state={{ from: location }} />
  );
};

export const ModelUserRoute: React.FC<ProtectedRouteProps> = ({
  element,
  redirectTo = LOGIN_PATH,
}) => {
  const { updateAuthStatus, isModelUser } = useAuth();

  const location = useLocation();
  useEffect(() => {
    updateAuthStatus();
  }, [updateAuthStatus]);

  if (isModelUser === null) {
    return <Loading />;
  }
  return isModelUser ? (
    element
  ) : (
    <Navigate to={redirectTo} state={{ from: location }} />
  );
};

export const StaffUserRoute: React.FC<ProtectedRouteProps> = ({
  element,
  redirectTo = LOGIN_PATH,
}) => {
  const { updateAuthStatus, isStaffUser } = useAuth();

  const location = useLocation();
  useEffect(() => {
    updateAuthStatus();
  }, [updateAuthStatus]);

  if (isStaffUser === null) {
    return <Loading />;
  }
  return isStaffUser ? (
    element
  ) : (
    <Navigate to={redirectTo} state={{ from: location }} />
  );
};

export const ModelOrStaffUserRoute: React.FC<ProtectedRouteProps> = ({
  element,
  redirectTo = LOGIN_PATH,
}) => {
  const { updateAuthStatus, isModelUser, isStaffUser } = useAuth();

  const location = useLocation();
  useEffect(() => {
    updateAuthStatus();
  }, [updateAuthStatus]);

  if (isModelUser === null || isStaffUser === null) {
    return <Loading />;
  }
  return isModelUser || isStaffUser ? (
    element
  ) : (
    <Navigate to={redirectTo} state={{ from: location }} />
  );
};
