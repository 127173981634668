import React from 'react';
import { Link } from 'react-router-dom';
import '../css/AboutUs.css';

const AboutUs: React.FC = () => {
  return (
    <div className="aboutus">
      <h1 className="aboutus__title">ABOUT US</h1>
      <p className="aboutus__description">ここに会社概要の内容を記載します。</p>

      <div className="aboutus__section">
        <h2 className="aboutus__section-title">DesingGo</h2>
        <p className="aboutus__section-description">
          1948年に創刊し、2018年に70周年を迎えた『美術手帖』が運営するアートのポータルサイト。
          2017年2月のローンチ以来、雑誌で培ったネットワークと信頼性に加え、
          ウェブメディアの機動性を活かしたコンテンツを発信しています。
          アートジャーナリズムとして、ニュースを中心に、アーティストのインタビュー、
          批評家やキュレーターによるレビュー、連載など幅広いコンテンツによって
          アートファンはもちろん、美術の専門家からも高い支持を得てきた美術手帖は、
          2018年7月、アートに関する総合的な情報を提供するプラットフォームへと進化します。
          全国の美術館・ギャラリーの展覧会情報を紹介する「EXHIBITIONS」、
          美術館・ギャラリーの基本情報を網羅した「MUSEUMS / GALLERIES」、
          国内外のアーティストを紹介する「ARTISTS」、美術の用語を解説する「ART
          WIKI」。 これらの機能を兼ね備えたアートポータルサイトです。
          数多のメディアが存在する今日において、アートメディアが果たすべき役割とは何か。
          この問いかけを続けながら、美術手帖は日本を代表するアートメディアとして
          アートと社会を接続させていきます。
        </p>
      </div>

      <div className="aboutus__section">
        <h2 className="aboutus__section-title">8BINGO</h2>
        <p className="aboutus__section-description">
          Momelaを運営する会社です。いつか所在地とか書く
        </p>
      </div>

      <div className="aboutus__section">
        <h2 className="aboutus__section-title">MEMBER</h2>
        <ul className="aboutus__list">
          <li className="aboutus__list-item">ようすけ</li>
          <li className="aboutus__list-item">とんきゅう</li>
          <li className="aboutus__list-item">かずま</li>
        </ul>
      </div>

      <div className="aboutus__section">
        <h2 className="aboutus__section-title">各種情報</h2>
        <ul className="aboutus__links">
          <li className="aboutus__link-item">
            <Link to="/privacy-policy">プライバシーポリシー</Link>
          </li>
          <li className="aboutus__link-item">
            <Link to="/disclaimer">免責事項</Link>
          </li>
          <li className="aboutus__link-item">
            <Link to="/contact">お問い合わせ</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AboutUs;
