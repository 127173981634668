import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import API from '../utils/API/API';
import GridPosts from './GridPosts';
import GridItems from './GridItems';
import '../css/Home.css';

interface Category {
  id: number;
  name: string;
  parent_id: number | null;
}

interface Item {
  id: number;
  categories: Category[];
  brand: {
    id: number;
    name: string;
    url: string;
    icon: string;
  };
  name: string;
  url: string;
  price: number;
  image: string;
}

interface Model {
  bio: string;
  height: number;
  icon: string;
  instagram: string;
}

interface Post {
  id: number;
  username: string;
  images: string[];
  items: Item[];
  modelinfo: Model;
}

const CategoryView: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [activeTab, setActiveTab] = useState<string>('posts');
  const [posts, setPosts] = useState<Post[]>([]);
  const [items, setItems] = useState<Item[]>([]);
  const [categoryAncestors, setCategoryAncestors] = useState<Category[]>([]);

  useEffect(() => {
    const fetchPostsAndItems = async () => {
      try {
        const response = await API().get<{ results: Post[] }>(
          `api/posts/?category=${id}`,
        );
        setPosts(response.data.results || []);
      } catch (error) {
        console.error('Failed to fetch posts:', error);
      }
      try {
        const response = await API().get<{ results: Item[] }>(
          `api/items/?category=${id}`,
        );
        setItems(response.data.results || []);
      } catch (error) {
        console.error('Failed to fetch items:', error);
      }

      try {
        const fetchCategoryAncestors = async (
          categoryId: number,
        ): Promise<Category[]> => {
          const categoryData = await API().get<Category>(
            `api/categories/${categoryId}/`,
          );
          const parentCategory = categoryData.data.parent_id
            ? await fetchCategoryAncestors(categoryData.data.parent_id)
            : [];
          return [...parentCategory, categoryData.data];
        };

        const ancestors = await fetchCategoryAncestors(Number(id));
        setCategoryAncestors(ancestors);
      } catch (error) {
        console.error('Failed to fetch category:', error);
      }
    };
    fetchPostsAndItems();
  }, [id]);

  return (
    <div className="home__container">
      <div className="home__breadcrumb">
        {categoryAncestors.map((category, index) => (
          <React.Fragment key={category.id}>
            <Link
              to={`/category/${category.id}`}
              className="home__breadcrumb-link"
            >
              {category.name}
            </Link>
            {index < categoryAncestors.length - 1 && (
              <span className="home__breadcrumb-separator"> &gt; </span>
            )}
          </React.Fragment>
        ))}
      </div>
      <div className="home__page-info">
        <ul className="home__tabs" role="tablist">
          <li className="home__tab-item">
            <button
              className={`home__tab-link ${activeTab === 'posts' ? 'home__tab-link--active' : ''}`}
              onClick={() => setActiveTab('posts')}
            >
              投稿
            </button>
          </li>
          <li className="home__tab-item">
            <button
              className={`home__tab-link ${activeTab === 'items' ? 'home__tab-link--active' : ''}`}
              onClick={() => setActiveTab('items')}
            >
              アイテム
            </button>
          </li>
        </ul>
      </div>

      <div className="home__tab-content">
        {activeTab === 'posts' && <GridPosts posts={posts} />}
        {activeTab === 'items' && <GridItems items={items} />}
      </div>
    </div>
  );
};

export default CategoryView;
