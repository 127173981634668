import { useNavigate } from 'react-router-dom';
import { LOGIN_PATH } from '../Constant';
import AuthAPI from './AuthAPI';

const OpenAuthAPI = (
  navigate?: ReturnType<typeof useNavigate>,
  redirectURL: string = LOGIN_PATH,
) => {
  return AuthAPI(navigate, redirectURL, true);
};

export default OpenAuthAPI;
