import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LOGIN_PATH } from '../utils/Constant';
import AuthAPI from '../utils/API/AuthAPI';
import { getRefreshToken } from '../utils/TokenManager';
import { useAuth } from '../utils/AuthContext';

const Logout: React.FC = () => {
  const navigate = useNavigate();
  const { updateAuthStatus, removeAuthInfo } = useAuth();
  const hasRequested = useRef(false);

  useEffect(() => {
    if (hasRequested.current) return;
    const refreshToken = getRefreshToken();
    if (refreshToken) {
      hasRequested.current = true;
      AuthAPI()
        .post('api/logout/', { refresh_token: refreshToken })
        .then(() => {
          removeAuthInfo();
          navigate(LOGIN_PATH);
        })
        .catch((error) => {
          console.error('There was an error during logout!', error);
        });
    } else {
      updateAuthStatus();
      navigate(LOGIN_PATH);
    }
  }, [navigate, updateAuthStatus, removeAuthInfo]);

  return <div>Logging out...</div>;
};

export default Logout;
