import axios, { InternalAxiosRequestConfig, AxiosResponse } from 'axios';
import { BASE_URL, LOGIN_PATH } from '../Constant';
import {
  getAccessToken,
  refreshAccessToken,
  clearTokens,
  getRefreshToken,
} from '../TokenManager';
import { useNavigate } from 'react-router-dom';

const AuthAPI = (
  navigate?: ReturnType<typeof useNavigate>,
  redirectURL: string = LOGIN_PATH,
  needNotToken?: boolean,
) => {
  const authAPI = axios.create({
    baseURL: BASE_URL,
  });

  authAPI.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
      const accessToken = getAccessToken();
      if (accessToken && config.headers) {
        config.headers.Authorization = `Bearer ${accessToken}`;
        return config;
      } else if (needNotToken) {
        return config;
      } else {
        return Promise.reject(new axios.Cancel('No access token available'));
      }
    },
    (error) => Promise.reject(error),
  );

  authAPI.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error) => {
      const originalRequest = error.config;
      const refreshToken = getRefreshToken();

      // if unauthorized error, resend request with new access_token.
      if (
        error.response?.status === 401 &&
        refreshToken &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true; // prevent infinite requests. maximum retry number is one.
        const new_access_token = await refreshAccessToken();
        if (new_access_token && originalRequest.headers) {
          originalRequest.headers.Authorization = `Bearer ${new_access_token}`;

          // if refresh_token is needed in request_data (such as /logout) overwrite that field.
          const parsedData = JSON.parse(originalRequest.data);
          if (parsedData.refresh_token) {
            parsedData.refresh_token = getRefreshToken();
          }
          originalRequest.data = JSON.stringify(parsedData);

          return axios(originalRequest);
        }
        clearTokens();
        navigate?.(redirectURL);
      }
      return Promise.reject(error);
    },
  );

  return authAPI;
};

export default AuthAPI;
