import React, { useState } from 'react';
import AuthAPI from '../utils/API/AuthAPI';
import '../css/StaffManageUser.css';

function StaffManageUser() {
  const [username, setUsername] = useState<string>('');
  const [successFlag, setSuccessFlag] = useState<boolean>(false);
  const [resMessage, setResMessage] = useState<string>('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const handleSubmit = async () => {
    await AuthAPI()
      .post('api/activate/modeluser/', {
        username: username,
      })
      .then(() => {
        setResMessage(`${username}の有効化に成功しました`);
        setSuccessFlag(true);
      })
      .catch((err) => {
        setResMessage(
          `有効化に失敗しました。\nエラーメッセージ\n${err.response.data.error}`,
        );
        setSuccessFlag(false);
        console.log(err);
      });
  };

  return (
    <div className="staff-manage-user">
      <h1 className="staff-manage-user__title">Staff Manage User</h1>
      <input
        type="text"
        value={username}
        onChange={handleInputChange}
        placeholder="username"
        className="staff-manage-user__input"
      />
      <button onClick={handleSubmit} className="staff-manage-user__button">
        有効化
      </button>
      <div
        className={`staff-manage-user__message ${successFlag ? 'success' : 'error'}`}
      >
        {resMessage}
      </div>
      {successFlag && (
        <a
          href={`/profile/${username}`}
          className="staff-manage-user__profile-link"
        >
          プロフィールページ
        </a>
      )}
    </div>
  );
}

export default StaffManageUser;
