import React from 'react';
import { Link } from 'react-router-dom';
import '../css/Header.css';
import { useSideBar } from '../utils/SideBarContext';
import { useSearch } from '../utils/SearchContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const SideBarToggleButton: React.FC = () => {
  const { isSideBarOpen, toggleSideBar } = useSideBar();
  return (
    <button
      className={`header__toggle-button ${isSideBarOpen ? 'open' : ''}`}
      onClick={toggleSideBar}
    >
      <>
        <span className="header__toggle-bar"></span>
        <span className="header__toggle-bar"></span>
        <span className="header__toggle-bar"></span>
      </>
    </button>
  );
};

const SearchButton: React.FC = () => {
  const { isSearchOpen, toggleSearch } = useSearch();
  return (
    <button
      className={`header__search-button" ${isSearchOpen ? 'open' : ''}`}
      onClick={toggleSearch}
    >
      <FontAwesomeIcon icon={faSearch} className="header__search-icon" />
    </button>
  );
};

const Header: React.FC = () => {
  return (
    <header className="header">
      <SideBarToggleButton />
      <Link to="/" className="header__title-link">
        <h1 className="header__title">Momela</h1>
      </Link>
      <SearchButton />
    </header>
  );
};

export default Header;
