import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import '../css/PrivacySection.css';
import AuthAPI from '../utils/API/AuthAPI';

const ChangeUserName: React.FC = () => {
  const [username, setUsername] = useState('');
  const [currentUsername, setCurrentUsername] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    AuthAPI()
      .get('api/mypage/')
      .then((response) => {
        setUsername(response.data.user.username);
        setCurrentUsername(response.data.user.username);
      })
      .catch((error) => console.error('failed to fetch /api/mypage', error));
  }, []);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (username === currentUsername) {
      setError('New username cannot be the same as the current username');
      setMessage('');
      return;
    }

    AuthAPI()
      .put('api/update-user-profile/', { username })
      .then(() => {
        setMessage('Username updated successfully');
        setError('');
        setCurrentUsername(username);
        navigate('/privacy');
      })
      .catch(() => {
        setMessage('');
        setError('Failed to update username');
      });
  };

  return (
    <div className="privacy-section privacy-section--username">
      <h1 className="privacy-section__title">ユーザーネームを変更する</h1>
      {message && <p className="privacy-section__message">{message}</p>}
      {error && <p className="privacy-section__error">{error}</p>}
      <form onSubmit={handleSubmit} className="privacy-section__form">
        <div className="privacy-section__form-group">
          <label htmlFor="username" className="privacy-section__label">
            新しいユーザーネーム
          </label>
          <input
            type="text"
            id="username"
            className="privacy-section__input"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="privacy-section__submit-button">
          保存
        </button>
      </form>
      <Link to="/privacy" className="privacy-section__back-link">
        Back
      </Link>
    </div>
  );
};

export default ChangeUserName;
