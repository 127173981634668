import React, { useEffect, useState } from 'react';
import OpenAuthAPI from '../utils/API/OpenAuthAPI';
import AuthAPI from '../utils/API/AuthAPI';
import '../css/StaffManagePost.css';

interface Brand {
  id: number;
  name: string;
  url: string;
  icon: string | null;
}

interface Item {
  id: number;
  brand: Brand;
  name: string;
  url: string;
  price: number;
  image: string;
}

interface Model {
  bio: string;
  height: number;
  icon: string | null;
  instagram: string;
}

interface Post {
  id: number;
  username: string;
  images: string[];
  items: Item[];
  modelinfo: Model;
  comment: string;
  is_active: boolean;
}

function StaffManagePost() {
  const [posts, setPosts] = useState<Post[]>([]);
  const [inputId, setInputId] = useState<string>('');
  const [activePost, setActivePost] = useState<Post | null>(null);
  const [successFetch, setSuccessFetch] = useState<boolean>(true);
  const [selectedPosts, setSelectedPosts] = useState<number[]>([]);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      const posts = await OpenAuthAPI()
        .get('/api/posts/?inactive=true')
        .then((res) => res.data.results)
        .catch(() => console.log('failed to fetch posts'));
      setPosts(posts);
    };

    fetchData();
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputId(event.target.value);
  };

  const handleSubmit = async () => {
    await AuthAPI()
      .get(`api/posts/${inputId}/?inactive=false`)
      .then((res) => {
        setActivePost(res.data);
        setSuccessFetch(true);
      })
      .catch((err) => {
        console.log(err);
        setActivePost(null);
        setSuccessFetch(false);
      });
  };

  const togglePostSelection = (postId: number) => {
    setSelectedPosts((prevSelectedPosts) =>
      prevSelectedPosts.includes(postId)
        ? prevSelectedPosts.filter((id) => id !== postId)
        : [...prevSelectedPosts, postId],
    );
  };

  const selectAllPosts = () => {
    if (isAllSelected) {
      setSelectedPosts([]); // すべての選択を解除
    } else {
      const allPostIds = posts.map((post) => post.id);
      setSelectedPosts(allPostIds); // すべての投稿を選択
    }
    setIsAllSelected(!isAllSelected); // フラグを反転
  };

  const activateSelectedPosts = async () => {
    try {
      await Promise.all(
        selectedPosts.map((postId) =>
          AuthAPI().get(`/api/activate-draft-post/?post_id=${postId}`),
        ),
      );
      alert('選択された投稿が表示されました。');
      setPosts(posts.filter((post) => !selectedPosts.includes(post.id)));
      setSelectedPosts([]); // 選択状態をリセット
    } catch (error) {
      console.error('一括表示に失敗しました', error);
    }
  };

  return (
    <div className="staff-manage-post">
      <h2 className="staff-manage-post__title">Staff Manage Post</h2>

      <div className="staff-manage-post__section-title">非表示の投稿一覧</div>

      {posts.length > 0 ? (
        posts.map((post) => (
          <div key={post.id} className="staff-manage-post__post-list">
            <input
              type="checkbox"
              checked={selectedPosts.includes(post.id)}
              onChange={() => togglePostSelection(post.id)}
              className="staff-manage-post__checkbox"
            />
            <a
              href={`/staff/preview-post-inactive/${post.id}`}
              className="staff-manage-post__post-link"
            >
              <img
                src={post.images[0]}
                alt={`Post by ${post.username}`}
                className="staff-manage-post__post-image"
              />
              <div>@{post.username}</div>
              <span className="staff-manage-post__arrow">→</span>
            </a>
          </div>
        ))
      ) : (
        <div className="staff-manage-post__no-posts">No Posts</div>
      )}

      <button onClick={selectAllPosts} className="staff-manage-post__button">
        {isAllSelected ? 'すべての投稿の選択を解除' : 'すべての投稿を選択'}
      </button>

      <button
        onClick={activateSelectedPosts}
        disabled={selectedPosts.length === 0}
        className="staff-manage-post__button"
      >
        選択した投稿を一括で表示
      </button>

      {/* すべての投稿を表示ボタン
      <button 
        onClick={handleActivateAllPosts} 
        className="staff-manage-post__button"
      >
        すべての投稿を表示
      </button> */}

      <div className="staff-manage-post__section-title">
        表示済みの投稿を隠す
      </div>
      <input
        type="text"
        value={inputId}
        onChange={handleInputChange}
        placeholder="Post ID で検索"
        className="staff-manage-post__input"
      />
      <button onClick={handleSubmit} className="staff-manage-post__button">
        検索
      </button>

      {activePost ? (
        activePost.is_active ? (
          <div className="staff-manage-post__post-list">
            <a
              href={`/staff/preview-post-active/${activePost.id}`}
              className="staff-manage-post__post-link"
            >
              <img
                src={activePost.images[0]} /* activePostの画像 */
                alt={`Post by ${activePost.username}`}
                className="staff-manage-post__post-image"
              />
              <div>@{activePost.username}</div>
              <span className="staff-manage-post__arrow">→</span>{' '}
              {/* 矢印アイコン */}
            </a>
          </div>
        ) : (
          <div className="staff-manage-post__inactive-post">
            <div className="staff-manage-post__post-link">
              <img
                src={activePost.images[0]} /* 非表示状態の投稿の画像 */
                alt={`Post by ${activePost.username}`}
                className="staff-manage-post__post-image"
              />
              <div>@{activePost.username}</div>
            </div>
            <div>既に非表示にされています。</div>
          </div>
        )
      ) : (
        !successFetch && (
          <div className="staff-manage-post__error">無効なPost IDです。</div>
        )
      )}
    </div>
  );
}

export default StaffManagePost;
