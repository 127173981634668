import React, { useState } from 'react';
import '../css/PrivacySection.css';
import AuthAPI from '../utils/API/AuthAPI';

const ResetPassword: React.FC = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    AuthAPI()
      .post('api/user/reset/password/', { email: email })
      .then(() => {
        setMessage(`パスワード再設定用のリンクを ${email} に送信しました`);
        setError('');
      })
      .catch(() => {
        setMessage('');
        setError('Failed to send password-reset email');
      });
  };

  return (
    <div className="privacy-section privacy-section--reset-password">
      <h1 className="privacy-section__title">パスワードをリセットする</h1>
      <p className="privacy-section__description">
        登録したメールアドレスにパスワード再設定用のリンクを送信します。
      </p>
      {message && <p className="privacy-section__message">{message}</p>}
      {error && <p className="privacy-section__error">{error}</p>}
      <form onSubmit={handleSubmit} className="privacy-section__form">
        <div className="privacy-section__form-group">
          <label htmlFor="email" className="privacy-section__label">
            Email
          </label>
          <input
            type="email"
            id="email"
            className="privacy-section__input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="privacy-section__submit-button">
          Send Email
        </button>
      </form>
      <a href="/privacy" className="privacy-section__back-link">
        Back
      </a>
    </div>
  );
};

export default ResetPassword;
