import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/PrivacySection.css';
import AuthAPI from '../utils/API/AuthAPI';

const ChangePassword: React.FC = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (newPassword !== newPasswordConfirm) {
      setError('New passwords do not match');
      setMessage('');
      return;
    }

    AuthAPI()
      .post('api/user/update/password/', {
        old_password: oldPassword,
        new_password: newPassword,
        new_password_confirm: newPasswordConfirm,
      })
      .then(() => {
        setMessage('Password changed successfully');
        setError('');
        navigate('/privacy');
      })
      .catch((error) => {
        setMessage('');
        if (error.response.data.error === 'incorrect_old_password') {
          setError('Incorrect old password');
        } else if (error.response.data.error === 'validation_error') {
          setError('不適切なパスワードです');
        } else {
          setError('Failed to change password');
        }
      });
  };

  return (
    <div className="privacy-section privacy-section--change-password">
      <h1 className="privacy-section__title">パスワードを変更する</h1>
      {message && <p className="privacy-section__message">{message}</p>}
      {error && <p className="privacy-section__error">{error}</p>}
      <form onSubmit={handleSubmit} className="privacy-section__form">
        <div className="privacy-section__form-group">
          <label htmlFor="oldPassword" className="privacy-section__label">
            現在のパスワード
          </label>
          <input
            type="password"
            id="oldPassword"
            className="privacy-section__input"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            required
          />
        </div>
        <div className="privacy-section__form-group">
          <label htmlFor="newPassword" className="privacy-section__label">
            新しいパスワード
          </label>
          <input
            type="password"
            id="newPassword"
            className="privacy-section__input"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </div>
        <p className="privacy-section__password-criteria">
          ・8文字以上で設定
          <br />
          ・アルファベットと数字と記号が使用可能
        </p>
        <div className="privacy-section__form-group">
          <label
            htmlFor="newPasswordConfirm"
            className="privacy-section__label"
          >
            新しいパスワードを確認する
          </label>
          <input
            type="password"
            id="newPasswordConfirm"
            className="privacy-section__input"
            value={newPasswordConfirm}
            onChange={(e) => setNewPasswordConfirm(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="privacy-section__submit-button">
          保存
        </button>
      </form>
      <a href="/privacy" className="privacy-section__back-link">
        Back
      </a>
    </div>
  );
};

export default ChangePassword;
