import React, { useState, useEffect } from 'react';
import AuthAPI from '../utils/API/AuthAPI';
import '../css/Home.css';
import Loading from './Loading';
import GridPosts from './GridPosts';

interface Item {
  id: number;
  name: string;
  url: string;
  price: number;
  image: string;
  brand: Brand;
}

interface Post {
  id: number;
  username: string;
  comment: string;
  images: string[];
  items: Item[];
  modelinfo: {
    bio: string;
    height: number;
    icon: string | null;
    instagram: string;
  };
}

interface Brand {
  id: number;
  name: string;
  url: string;
  icon: string;
}

const Favorite: React.FC = () => {
  const [posts, setPosts] = useState<Post[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AuthAPI()
      .get('api/favorites/')
      .then((response) => {
        setPosts(response.data.posts || []);
        setLoading(false);
      })
      .catch((error) => {
        console.error('There was an error fetching the favorites!', error);
        setPosts([]);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="home__container">
      <h2 className="home__page-title">Favorite</h2>
      {posts.length > 0 ? (
        <GridPosts posts={posts} />
      ) : (
        <p className="home__no-posts">No favorite posts.</p>
      )}
    </div>
  );
};

export default Favorite;
