import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import '../css/Profile.css';
import API from '../utils/API/API';
import Loading from './Loading';
import { DEFAULT_MODELUSER_ICON } from '../utils/Constant';

interface Profile {
  username: string;
  model: ModelInfo;
  posts: Post[];
}

interface Post {
  id: number;
  images: string[];
  comment: string;
}

interface ModelInfo {
  icon: string | null;
  instagram: string;
  bio: string;
  height: string;
  skelton: { id: number; name: string } | null;
}

const ProfileView: React.FC = () => {
  const { username } = useParams<{ username: string }>();
  const [modelProfile, setProfile] = useState<Profile | null>(null);

  useEffect(() => {
    API()
      .get(`api/profile/${username}/`)
      .then((response) => {
        setProfile(response.data);
      })
      .catch((error) => {
        console.error('There was an error fetching the profile data!', error);
      });
  }, [username]);

  if (!modelProfile || !modelProfile.model) return <Loading />;

  return (
    <>
      <div className="profile-page-container">
        <img
          className="profile-page-icon"
          src={modelProfile.model.icon || DEFAULT_MODELUSER_ICON}
          alt={`${modelProfile.username}'s icon`}
        />
        <div className="profile-page-info">
          <h2>{modelProfile.username}</h2>
          <p>
            Instagram:{' '}
            {modelProfile.model.instagram
              ? `@${modelProfile.model.instagram}`
              : '未設定'}
          </p>
          <p>bio: {modelProfile.model.bio || '未設定'}</p>
          <p>Height: {modelProfile.model.height || '未設定'}cm</p>
          <p>Skelton: {modelProfile.model.skelton?.name || '未設定'}</p>
        </div>
        <div>
          <div className="profile-page-buttons">
            <a
              href={`https://www.instagram.com/${modelProfile.model.instagram}`}
              className="instagram-button"
            >
              Instagram
            </a>
          </div>
        </div>
      </div>
      <div className="profile-page-posts-container">
        <div className="profile-page-grid">
          {modelProfile.posts &&
            modelProfile.posts.length > 0 &&
            modelProfile.posts.map((post) => (
              <div key={post.id} className="profile-page-grid-item">
                <a href={`/posts/${post.id}`}>
                  <div className="profile-page-grid-item-inner">
                    <img src={post.images[0]} alt={post.comment} />
                  </div>
                </a>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default ProfileView;
