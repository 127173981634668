import React, { useEffect, useState } from 'react';
import OpenAuthAPI from '../utils/API/OpenAuthAPI';
import AuthAPI from '../utils/API/AuthAPI';
import '../css/StaffManageItem.css';

interface Brand {
  id: number;
  name: string;
  url: string;
  icon: string | null;
}

interface Item {
  id: number;
  brand: Brand;
  name: string;
  url: string;
  price: number;
  image: string;
}

interface MercariUrl {
  id: number;
  item_id: number;
  url: string;
  price: number | null;
  size: string | null;
  status: string | null;
}

function StaffManageItemMercari() {
  const [items, setItems] = useState<Item[]>([]);
  const [searchName, setSearchName] = useState<string>(''); // 名前検索用のステート
  const [filteredItems, setFilteredItems] = useState<Item[]>([]); // フィルタリング後のアイテム
  const [selectedItem, setSelectedItem] = useState<Item | null>(null); // 選択されたアイテム
  const [mercariUrl, setMercariUrl] = useState<MercariUrl | null>(null);
  const [newUrl, setNewUrl] = useState<string>('');
  const [price, setPrice] = useState<number | null>(null);
  const [size, setSize] = useState<string | null>('');
  const [status, setStatus] = useState<string | null>('');
  const [successFetch, setSuccessFetch] = useState<boolean>(true);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  // 初回ロードでアイテム一覧を取得
  useEffect(() => {
    const fetchItemData = async () => {
      try {
        const response = await OpenAuthAPI().get('/api/items/');
        setItems(response.data.results);
        setFilteredItems(response.data.results); // 最初は全アイテムを表示
      } catch (error) {
        console.log('Failed to fetch items:', error);
      }
    };

    fetchItemData();
  }, []);

  const validateForm = () => {
    const formErrors: { [key: string]: string } = {};

    if (!newUrl) {
      formErrors['newUrl'] = 'メルカリURLは必須です。';
    }

    setErrors(formErrors);

    return Object.keys(formErrors).length === 0;
  };

  // 名前での検索処理
  const handleNameSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value;
    setSearchName(searchValue);

    // 名前でアイテムをフィルタリング
    const filtered = items.filter((item) =>
      item.name.toLowerCase().includes(searchValue.toLowerCase()),
    );
    setFilteredItems(filtered);
  };

  // アイテム選択処理 & Mercari URL検索処理
  const handleSelectItem = async (item: Item) => {
    setSelectedItem(item);
    try {
      const mercariResponse = await AuthAPI().get(
        `/api/mercari-urls/?item_id=${item.id}`,
      );
      if (mercariResponse.data.urls.length > 0) {
        setMercariUrl(mercariResponse.data.urls[0]);
      } else {
        setMercariUrl(null);
      }
      setSuccessFetch(true);
    } catch (error) {
      console.log('Failed to fetch Mercari URL:', error);
      setMercariUrl(null);
      setSuccessFetch(true);
    }
  };

  // URL追加処理
  const handleAddUrl = async () => {
    if (!selectedItem) {
      console.log('Item not selected');
      return;
    }

    if (!validateForm()) {
      console.log('Validation failed');
      return;
    }

    try {
      const data = {
        item: selectedItem.id, // 選択されたアイテムIDを使用
        url: newUrl,
        price: price || null,
        size: size || null,
        status: status || null,
      };
      console.log('Successfully added Mercari URL:', data);
      const response = await AuthAPI().post(`/api/mercari-urls/`, data);

      setSuccessFetch(true);
      setMercariUrl(response.data);

      window.location.reload();
    } catch (error) {
      console.log('Failed to add Mercari URL:', error);
      setSuccessFetch(false);
    }
  };

  const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewUrl(event.target.value);
  };

  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrice(event.target.value ? parseInt(event.target.value) : null);
  };

  const handleSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSize(event.target.value);
  };

  const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStatus(event.target.value);
  };

  return (
    <div className="staff-manage-item">
      <h2 className="staff-manage-item__title">商品追加</h2>

      {/* 名前検索の入力フィールド */}
      <input
        type="text"
        value={searchName}
        onChange={handleNameSearch}
        placeholder="アイテム名で検索"
        className="staff-manage-item__input"
      />

      {/* アイテムの一覧表示 */}
      <div className="staff-manage-item__list">
        {filteredItems.length > 0 ? (
          filteredItems.map((item) => (
            <div
              key={item.id}
              className={`staff-manage-item__list-item ${selectedItem && selectedItem.id === item.id ? 'selected' : ''}`}
              onClick={() => handleSelectItem(item)}
            >
              <div className="staff-manage-item__list-item-content">
                <div>{item.name}</div>
                <div>{item.brand ? item.brand.name : 'ブランドなし'}</div>
              </div>
            </div>
          ))
        ) : (
          <p>商品が見つかりませんでした。</p>
        )}
      </div>

      {selectedItem && (
        <div className="staff-manage-item__selected">
          <h2>選択された商品</h2>
          <p>アイテム名: {selectedItem.name}</p>
          <p>
            価格: ￥
            {selectedItem.price !== null &&
            selectedItem.price !== undefined &&
            selectedItem.price !== 0
              ? selectedItem.price.toLocaleString()
              : null}
          </p>
          {selectedItem.url && (
            <p>
              <a
                href={selectedItem.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                公式サイト
              </a>
            </p>
          )}

          {/* Mercari URL が存在するかどうかを表示 */}
          {mercariUrl ? (
            <div className="staff-manage-item__mercari-url">
              <p>
                メルカリURL:{' '}
                <a
                  href={mercariUrl.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {mercariUrl.url}
                </a>
              </p>
              {mercariUrl.price && (
                <p>価格: ￥{mercariUrl.price.toLocaleString()}</p>
              )}
              {mercariUrl.size && <p>サイズ: {mercariUrl.size}</p>}
              {mercariUrl.status && <p>ステータス: {mercariUrl.status}</p>}
            </div>
          ) : (
            <p>この商品にはまだメルカリURLが紐づけられていません。</p>
          )}
        </div>
      )}

      {/* URL追加フォーム */}
      <div className="staff-manage-item__form">
        <input
          type="text"
          value={newUrl}
          onChange={handleUrlChange}
          placeholder="メルカリURLを入力"
          className="staff-manage-item__input"
        />
        {errors.newUrl && <p className="error">{errors.newUrl}</p>}
        <input
          type="number"
          value={price !== null ? price : ''}
          onChange={handlePriceChange}
          placeholder="価格を入力 (任意)"
          className="staff-manage-item__input"
        />
        <input
          type="text"
          value={size || ''}
          onChange={handleSizeChange}
          placeholder="サイズを入力 (任意)"
          className="staff-manage-item__input"
        />
        <input
          type="text"
          value={status || ''}
          onChange={handleStatusChange}
          placeholder="ステータスを入力 (任意)"
          className="staff-manage-item__input"
        />
        <button onClick={handleAddUrl} className="staff__button">
          URLを追加
        </button>
        {!successFetch && (
          <div className="staff-manage-item__error">
            URLの追加に失敗しました。
          </div>
        )}
      </div>
      <br />
      <a href="/staff/manage-item-add" className="stafftop__link">
        アイテム登録ページへ
      </a>
    </div>
  );
}

export default StaffManageItemMercari;
