import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import API from '../utils/API/API';
import '../css/BrandList.css';
import { DEFAULT_BRAND_ICON } from '../utils/Constant';

interface Brand {
  id: number;
  name: string;
  url: string;
  icon: string | null;
}

const BrandList = () => {
  const [brands, setBrands] = useState<Brand[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filteredBrands, setFilteredBrands] = useState<Brand[]>([]);

  useEffect(() => {
    API()
      .get('api/brands/')
      .then((response) => {
        setBrands(response.data.results);
      })
      .catch((error) => console.error('Error loading brands:', error));
  }, []);

  useEffect(() => {
    const filtered = brands.filter((brand) =>
      brand.name.toLowerCase().includes(searchTerm.toLowerCase()),
    );
    setFilteredBrands(filtered);
  }, [searchTerm, brands]);

  return (
    <div className="brand-list-container">
      <input
        type="text"
        placeholder="ブランドをさがす"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="brands__input"
      />
      <div className="brands-display">
        {filteredBrands.map((brand) => (
          <Link key={brand.id} to={`/brand/${brand.id}`} className="brand-item">
            <img src={brand.icon || DEFAULT_BRAND_ICON} alt={brand.name} />
            <span>{brand.name}</span>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default BrandList;
