import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import API from '../utils/API/API';
import '../css/CategorySearch.css';
import BackArrowIcon from '../assets/icon/backarrow';

interface Category {
  id: number;
  name: string;
  children: Category[];
  parent_id: number | null;
}

const CategoryForCreatePost: React.FC = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [filteredCategories, setFilteredCategories] = useState<Category[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [categoryStack, setCategoryStack] = useState<Category[]>([]);
  const [rootCategories, setRootCategories] = useState<Category[]>([]); // ルートカテゴリを保存するための状態
  const navigate = useNavigate();
  const location = useLocation();
  const selectedItems = location.state?.selectedItems || [];
  const previousPath = location.state?.previousPath || '/create/post';
  const formData = location.state?.formData || {
    comment: '',
    items: [],
    images: [],
    is_active: true,
  };
  const imagePreviews = location.state?.imagePreviews || [];

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = (parentId: number | null = null) => {
    const endpoint = parentId
      ? `api/categories/${parentId}/`
      : 'api/categories/';
    API()
      .get(endpoint)
      .then((response) => {
        const categoriesData = response.data.children || response.data;
        setCategories(categoriesData);
        if (parentId === null) {
          setRootCategories(categoriesData);
        }
      })
      .catch((error) => console.error('Failed to fetch categories:', error));
  };

  useEffect(() => {
    const filtered = categories.filter((category) =>
      category.name.toLowerCase().includes(searchTerm.toLowerCase()),
    );
    setFilteredCategories(filtered);
  }, [searchTerm, categories]);

  const handleCategoryClick = (category: Category) => {
    if (category.children && category.children.length > 0) {
      setCategoryStack((prevStack) => [...prevStack, category]);
      setCategories(category.children);
    } else {
      navigate(previousPath, {
        state: {
          ...location.state,
          formData: {
            ...formData,
          },
          imagePreviews,
          categoryId: category.id,
          categoryName: category.name,
          selectedItems,
        },
      });
    }
  };

  const handleAllCategoriesClick = () => {
    if (categoryStack.length > 0) {
      const parentCategory = categoryStack[categoryStack.length - 1];
      navigate('/create/post', {
        state: {
          categoryId: parentCategory.id,
          categoryName: parentCategory.name,
        },
      });
    }
  };

  const handleBackClick = () => {
    if (categoryStack.length > 0) {
      const newStack = [...categoryStack];
      newStack.pop();
      setCategoryStack(newStack);
      if (newStack.length > 0) {
        setCategories(newStack[newStack.length - 1].children);
      } else {
        setCategories(rootCategories);
      }
    } else {
      navigate(previousPath, {
        state: {
          ...location.state,
          formData,
          imagePreviews,
          selectedItems,
        },
      });
    }
  };

  return (
    <div className="category-search__container">
      <button
        onClick={handleBackClick}
        className="category-search__back-button"
      >
        <BackArrowIcon />
      </button>
      <h2 className="category-search__title">カテゴリ</h2>
      {/* 検索ボックス */}
      <input
        type="text"
        placeholder="カテゴリをさがす"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="category-search__input"
      />
      {categoryStack.length > 0 && (
        <div className="category-search__item">
          <button
            onClick={handleAllCategoriesClick}
            className="category-search__link"
          >
            すべて
          </button>
        </div>
      )}
      {filteredCategories.map((category) => (
        <div key={category.id} className="category-search__item">
          <button
            onClick={() => handleCategoryClick(category)}
            className="category-search__link"
          >
            {category.name}
          </button>
        </div>
      ))}
    </div>
  );
};

export default CategoryForCreatePost;
