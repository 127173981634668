import React from 'react';
import '../css/StaffTop.css';

function StaffTop() {
  return (
    <div className="stafftop">
      <h2 className="stafftop__title">Staff Top</h2>
      <div className="stafftop__link-container">
        <a
          href="/staff/manage-post"
          className="stafftop__link stafftop__button"
        >
          投稿管理
        </a>
      </div>
      <div className="stafftop__link-container">
        <a
          href="/staff/manage-item-add"
          className="stafftop__link stafftop__button"
        >
          アイテム登録
        </a>
      </div>
      <div className="stafftop__link-container">
        <a
          href="/staff/manage-item-mercari"
          className="stafftop__link stafftop__button"
        >
          商品とメルカリurlの紐付け
        </a>
      </div>
      {/* <div className="stafftop__link-container">
  <a href="/staff/manage-user" className="stafftop__link stafftop__button">
    ユーザー管理
  </a>
</div> */}
    </div>
  );
}

export default StaffTop;
