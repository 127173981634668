import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../css/PostImageSlider.css';

interface PostData {
  images: string[];
}

const PostImageSlider: React.FC<{ post: PostData }> = ({ post }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const totalSlides = post.images.length;

  const sliderSettings = {
    dots: totalSlides > 1,
    infinite: false,
    arrows: totalSlides > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current: number, next: number) => setCurrentSlide(next),
    nextArrow: <NextArrow show={currentSlide < totalSlides - 1} />,
    prevArrow: <PrevArrow show={currentSlide > 0} />,
  };

  return (
    <div className="post__image-slider">
      <Slider {...sliderSettings}>
        {post.images.map((image, index) => (
          <div key={index} className="post-image-wrapper">
            <img
              src={image}
              alt={`投稿画像 ${index + 1}`}
              className="post-image"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

const NextArrow = (props: {
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
  show: boolean;
}) =>
  props.show ? (
    <div {...props} className={`${props.className} slick-next`} />
  ) : null;

const PrevArrow = (props: {
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
  show: boolean;
}) =>
  props.show ? (
    <div {...props} className={`${props.className} slick-prev`} />
  ) : null;

export default PostImageSlider;
