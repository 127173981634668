import React from 'react';
import '../css/AboutUs.css';

const Disclaimer: React.FC = () => {
  return (
    <div className="disclaimer">
      <h1 className="disclaimer__title">免責事項</h1>
      <p className="disclaimer__description">
        ここに免責事項の内容を記載します。
      </p>
    </div>
  );
};

export default Disclaimer;
