import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import API from '../utils/API/API';
import GridPosts from './GridPosts';
import GridItems from './GridItems';
import '../css/Home.css';
import { DEFAULT_BRAND_ICON } from '../utils/Constant';

interface Brand {
  id: number;
  name: string;
  url: string;
  icon: string | null;
}

interface Item {
  id: number;
  brand: Brand;
  name: string;
  url: string;
  price: number;
  image: string;
}

interface Model {
  bio: string;
  height: number;
  icon: string | null;
  instagram: string;
}

interface Post {
  id: number;
  username: string;
  images: string[];
  items: Item[];
  modelinfo: Model;
}

const BrandView: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [activeTab, setActiveTab] = useState<string>('posts');
  const [posts, setPosts] = useState<Post[]>([]);
  const [items, setItems] = useState<Item[]>([]);
  const [brand, setBrand] = useState<Brand>({
    id: 0,
    name: '',
    url: '',
    icon: null,
  });

  useEffect(() => {
    const fetchPostsAndItems = async () => {
      try {
        const response = await API().get<{ results: Post[] }>(
          `api/posts/?brand=${id}`,
        );
        setPosts(response.data.results);
      } catch (error) {
        console.error('Failed to fetch posts:', error);
        setPosts([]);
      }
      try {
        const response = await API().get<{ results: Item[] }>(
          `api/items/?brand=${id}`,
        );
        setItems(response.data.results);
      } catch (error) {
        console.error('Failed to fetch items:', error);
        setItems([]);
      }

      setBrand(
        await API()
          .get(`api/brands/${id}/`)
          .then((res) => res.data)
          .catch((err) => console.log('Failed to fetch brand:', err)),
      );
    };
    fetchPostsAndItems();
  }, [id]);

  return (
    <div className="home__container">
      <div className="home__page-info">
        <img
          src={brand?.icon || DEFAULT_BRAND_ICON}
          alt={`${brand?.name} icon`}
          className="home__brand-icon"
        />
        <h2 className="home__page-title">{brand?.name}</h2>
        <a
          href={brand?.url}
          target="_blank"
          rel="noopener noreferrer"
          className="home__brandurl"
        >
          公式サイト
        </a>
      </div>
      <ul className="home__tabs" role="tablist">
        <li className="home__tab-item">
          <button
            className={`home__tab-link ${activeTab === 'posts' ? 'home__tab-link--active' : ''}`}
            onClick={() => setActiveTab('posts')}
          >
            投稿
          </button>
        </li>
        <li className="home__tab-item">
          <button
            className={`home__tab-link ${activeTab === 'items' ? 'home__tab-link--active' : ''}`}
            onClick={() => setActiveTab('items')}
          >
            アイテム
          </button>
        </li>
      </ul>

      <div className="home__tab-content">
        {activeTab === 'posts' && <GridPosts posts={posts} />}
        {activeTab === 'items' && <GridItems items={items} />}
      </div>
    </div>
  );
};

export default BrandView;
