import React, { useEffect, useState } from 'react';
import '../css/Login.css';
import { useLocation, useNavigate } from 'react-router-dom';
import API from '../utils/API/API';
import { setAccessToken, setRefreshToken } from '../utils/TokenManager';
import { useAuth } from '../utils/AuthContext';

const Login: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { updateAuthStatus, isAuthenticated } = useAuth();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    try {
      const response = await API().post('api/login/', {
        username: username,
        password: password,
      });
      if (response.data.access_token) {
        setRefreshToken(response.data.refresh_token);
        setAccessToken(response.data.access_token);
        updateAuthStatus();
        navigate(location.state?.from?.pathname || '/');
      } else {
        setError('Invalid credentials');
      }
    } catch {
      setError('Invalid credentials');
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [navigate, isAuthenticated]);

  return (
    <div className="login">
      <h1 className="login__title">Log In</h1>
      <p className="login__explanation">
        ログインすることで、お気に入りの投稿を保存することができます。
      </p>
      {error && <p className="login__error">{error}</p>}
      <form onSubmit={handleSubmit} className="login__form">
        <div className="login__form-group">
          <label htmlFor="username" className="login__label">
            Username
          </label>
          <input
            type="text"
            id="username"
            name="username"
            className="login__input"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            autoComplete="username"
          />
        </div>
        <div className="login__form-group">
          <label htmlFor="password" className="login__label">
            Password
          </label>
          <input
            type="password"
            id="password"
            name="password"
            className="login__input"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="current-password"
          />
        </div>
        <button type="submit" className="login__btn login__btn--custom">
          Log In
        </button>
      </form>
      <div className="login__links">
        <p className="login__link-text">Do not have an account?</p>
        <a href="/signup" className="login__btn login__btn--signup">
          Sign up here
        </a>
      </div>
      <div className="login__links">
        <p className="login__link-text">Forgot password?</p>
        <a href="/reset/password" className="login__btn login__btn--signup">
          Reset password
        </a>
      </div>
    </div>
  );
};

export default Login;
