import React, { useState, useEffect } from 'react';
import '../css/Modal.css';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
  const [fadeType, setFadeType] = useState<'fade-in' | 'fade-out'>('fade-in');

  useEffect(() => {
    if (!isOpen) {
      setFadeType('fade-out');
    } else {
      setFadeType('fade-in');
    }
  }, [isOpen]);

  const handleAnimationEnd = () => {
    if (fadeType === 'fade-out') {
      onClose();
    }
  };

  if (!isOpen && fadeType === 'fade-out') return null;

  return (
    <div className="modal__overlay" onClick={() => setFadeType('fade-out')}>
      <div
        className={`modal__content ${fadeType === 'fade-out' ? 'modal__content--fade-out' : ''}`}
        onClick={(e) => e.stopPropagation()}
        onAnimationEnd={handleAnimationEnd}
      >
        <button
          className="modal__close-button"
          onClick={() => setFadeType('fade-out')}
        >
          X
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
