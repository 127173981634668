import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AuthAPI from '../utils/API/AuthAPI';
import PostImageSlider from './PostImageSlider';
import { faHeart as fasHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from './Loading';
import ItemSlider from './ItemSlider';

interface Category {
  id: number;
  name: string;
}

interface Brand {
  id: number;
  name: string;
  url: string;
  icon: string | null;
}

interface Skelton {
  id: number;
  name: string;
}

interface Item {
  id: number;
  name: string;
  price: number;
  image: string;
  url: string;
  brand: Brand | null;
  categories: Category[];
}

interface ModelInfo {
  bio: string;
  height: number;
  skelton: Skelton | null;
  icon: string | null;
  instagram: string;
}

interface PostData {
  id: number;
  username: string;
  modelinfo: ModelInfo;
  images: string[];
  comment: string;
  items: Item[];
  favorite_num: number;
  created_at: string;
  is_active: boolean;
}

function StaffPreviewInActivatedPost() {
  const { id, activate } = useParams<{ id: string; activate: string }>();
  const [post, setPost] = useState<PostData | null>(null);
  const [isFavorite, setIsFavorite] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPostData = async () => {
      try {
        const response = await AuthAPI().get(`api/posts/${id}/?inactive=true`);
        const postData: PostData = response.data;
        setPost(postData);

        setIsFavorite(
          await AuthAPI()
            .get('api/favorites/')
            .then((res) => res.data.posts.map((post: PostData) => post.id))
            .then((posts: number[]) => posts.includes(Number(id)))
            .catch(() => {
              console.error('could not get favorites');
              return false;
            }),
        );
      } catch (error) {
        console.error('could not get post', error);
      }
    };

    fetchPostData();
  }, [id, activate]);

  const toggleFavorite = async () => {
    try {
      if (isFavorite) {
        await AuthAPI().delete(`api/favorites/`, {
          data: { post_id: id },
        });
        setIsFavorite(false);
      } else {
        await AuthAPI().post(`api/favorites/`, { post_id: id });
        setIsFavorite(true);
      }
    } catch (error) {
      console.error(
        `お気に入りの${isFavorite ? '削除' : '追加'}に失敗しました`,
        error,
      );
    }
  };

  const activatePostDraft = (id: string | undefined) => {
    if (!id) {
      console.error('no post id was given');
      return;
    }
    AuthAPI()
      .get(`api/activate-draft-post/?post_id=${id}`)
      .then(() => {
        navigate('/staff/manage-post');
      })
      .catch((err) => console.log(err));
  };

  const deactivatePostDraft = (id: string | undefined) => {
    if (!id) {
      console.error('no post id was given');
      return;
    }
    AuthAPI()
      .get(`api/deactivate-draft-post/?post_id=${id}`)
      .then(() => {
        navigate('/staff/manage-post');
      })
      .catch((err) => console.log(err));
  };

  if (!post) return <Loading />;

  return (
    <div className="post__container">
      <div>
        <div className="post__image-container">
          <PostImageSlider post={post} />
        </div>
        <div className="post__below">
          <div className="post__user-info">
            <a href={`/profile/${post.username}`}>
              <span className="post__user-name">@{post.username}</span>
            </a>
            <button
              className={`post__btn-favorite ${isFavorite ? 'post__btn-favorite--active' : ''}`}
              onClick={toggleFavorite}
            >
              <FontAwesomeIcon
                icon={isFavorite ? fasHeart : farHeart}
                style={{
                  color: isFavorite ? '#FF0000' : '#000000',
                }}
              />
            </button>
          </div>
          <p>{post.comment}</p>

          {post.items &&
            post.items.length > 0 &&
            post.items.reduce((acc: JSX.Element[], item) => {
              if (
                item.brand &&
                !acc.find((it) => it.props.children === item.brand!.name)
              ) {
                acc.push(
                  <a
                    key={item.id}
                    href={`/brand/${item.brand!.id}`}
                    className="post__category-link"
                  >
                    {item.brand!.name}
                  </a>,
                );
              }
              return acc;
            }, [])}
        </div>
      </div>

      <div className="post__used-item">
        <h2 className="post__item-title">使用アイテム</h2>
        <ItemSlider items={post.items} />
      </div>
      {post.is_active ? (
        <button
          type="button"
          onClick={() => deactivatePostDraft(id)}
          className="staff-manage-post__button"
        >
          この投稿を隠す
        </button>
      ) : (
        <button
          type="button"
          onClick={() => activatePostDraft(id)}
          className="staff-manage-post__button"
        >
          この投稿を表示する
        </button>
      )}
    </div>
  );
}

export default StaffPreviewInActivatedPost;
