import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import API from '../utils/API/API';
import GridPosts from './GridPosts';
import GridItems from './GridItems';
import '../css/Home.css';
import '../css/SearchResult.css';

interface SearchResults {
  items: Item[];
  posts: Post[];
}

interface Item {
  id: number;
  name: string;
  url: string;
  price: number;
  image: string;
  brand: Brand;
  categories: Category[];
}

interface Post {
  id: number;
  username: string;
  comment: string;
  images: string[];
  items: Item[];
  modelinfo: {
    bio: string;
    height: number;
    icon: string | null;
    instagram: string;
  };
}

interface Category {
  id: number;
  name: string;
  children: Category[];
  parent_id: number | null;
}

interface Brand {
  id: number;
  name: string;
  url: string;
  icon: string;
}

const SearchResult: React.FC = () => {
  const { keyword } = useParams<{ keyword?: string }>();
  const [results, setResults] = useState<SearchResults | null>(null);
  const [activeTab, setActiveTab] = useState('posts');

  useEffect(() => {
    if (keyword) {
      fetchResults(keyword);
    }
  }, [keyword]);

  const fetchResults = async (keyword: string) => {
    try {
      const response = await API().post<SearchResults>(
        `/api/search/universal/?query=${keyword}`,
      );
      setResults(response.data);
    } catch (error) {
      console.error('Failed to fetch results:', error);
    }
  };

  return (
    <div className="home__container">
      <h1 className="searchresult__title">{keyword} の検索結果</h1>
      <ul className="home__tabs" role="tablist">
        <li className="home__tab-item">
          <button
            className={`home__tab-link ${activeTab === 'posts' ? 'home__tab-link--active' : ''}`}
            onClick={() => {
              setActiveTab('posts');
            }}
          >
            投稿
          </button>
        </li>
        <li className="home__tab-item">
          <button
            className={`home__tab-link ${activeTab === 'items' ? 'home__tab-link--active' : ''}`}
            onClick={() => {
              setActiveTab('items');
            }}
          >
            アイテム
          </button>
        </li>
      </ul>
      {results && (
        <div className="home__tab-content">
          {activeTab === 'posts' && <GridPosts posts={results.posts} />}
          {activeTab === 'items' && <GridItems items={results.items} />}
        </div>
      )}
    </div>
  );
};

export default SearchResult;
