import React from 'react';
import '../css/GridItems.css';

interface Item {
  id: number;
  name: string;
  url: string;
  price: number;
  image: string;
}

interface GridItemsProps {
  items: Item[];
}

const GridItems: React.FC<GridItemsProps> = ({ items }) => {
  return (
    <div className="griditems__item-row">
      {items.length > 0 ? (
        items.map((item) => (
          <div key={item.id} className="griditems__item-box">
            <div className="griditems__item-image-wrapper">
              <img
                src={item.image}
                alt={item.name}
                className="griditems__item-image"
              />
            </div>
            <div className="griditems__item-info">
              <p className="griditems__item-name">{item.name}</p>
              <p className="griditems__item-price">
                {item.price > 0 && `￥${item.price.toLocaleString()}`}
              </p>
            </div>
          </div>
        ))
      ) : (
        <p>No items found.</p>
      )}
    </div>
  );
};

export default GridItems;
