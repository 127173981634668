import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import '../css/Itemlist.css';
import { DEFAULT_MODELUSER_ICON } from '../utils/Constant';
import API from '../utils/API/API';

interface Model {
  bio: string;
  height: number;
  icon: string | null;
  instagram: string;
}

interface Post {
  id: number;
  username: string;
  images: string[];
  items: Item[];
  modelinfo: Model;
}

interface Item {
  id: number;
  name: string;
  image: string;
  price: number;
  brand: Brand;
  url: string;
  comment: string | null;
  mercari_search_url: string | null;
  categories: Category[];
}

interface Category {
  id: number;
  name: string;
}

interface Brand {
  id: number;
  name: string;
  url: string;
  icon: string | null;
}

interface MercariUrl {
  id: number;
  item_id: number;
  url: string;
  price: number | null;
  size: string | null;
  status: string | null;
}

const ItemList: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [posts, setPosts] = useState<Post[]>([]);
  const [item, setItem] = useState<Item | null>(null);
  const [mercariUrls, setMercariUrls] = useState<MercariUrl[]>([]);
  const [activeTab, setActiveTab] = useState('info');

  useEffect(() => {
    const fetchPostsAndItem = async () => {
      try {
        const itemResponse = await API().get(`api/items/${id}/`);
        const itemData = itemResponse.data;
        setItem(itemData);

        const postsResponse = await API().get(`api/posts/?item=${id}`);
        const postsData = postsResponse.data.results;
        setPosts(postsData);

        const mercariurlResponse = await API().get(
          `api/mercari-urls/?item_id=${id}`,
        );
        const mercariurlData = mercariurlResponse.data.urls;
        setMercariUrls(mercariurlData);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };
    fetchPostsAndItem();
  }, [id]);

  const showPosts = (posts: Post[]) => {
    return (
      <div className="gridposts__post-row">
        {posts.length > 0 ? (
          posts.map((post) => (
            <div key={post.id} className="gridposts__post-item">
              <div className="gridposts__post-card">
                <a href={`/posts/${post.id}`}>
                  <div className="gridposts__post-image-wrapper">
                    <img
                      src={post.images[0]}
                      className="gridposts__post-image"
                      alt="Post"
                    />
                  </div>
                </a>
                <div className="gridposts__post-info">
                  <div className="gridposts__user-info">
                    <img
                      src={post.modelinfo.icon || DEFAULT_MODELUSER_ICON}
                      alt={post.username}
                      className="gridposts__user-icon"
                    />
                    <p className="gridposts__username">{post.username}</p>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No posts found for this item.</p>
        )}
      </div>
    );
  };

  const showMercariUrls = (urls: MercariUrl[]) => {
    return (
      <div className="itemlist__mercari">
        <h3 className="itemlist__mercari-title">メルカリのおすすめ商品</h3>
        {urls.map((mercari) => (
          <div key={mercari.id} className="itemlist__item-card">
            <a
              href={mercari.url}
              className="itemlist__item-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={`https://ambassador-system.mercari.com/v1/i?id=${mercari.url.split('/item/')[1]}&svc=m`}
                className="itemlist__mercari-image"
                alt="Item"
              />
            </a>
            <div className="itemlist__purchase">
              <div className="itemlist__price-status">
                <p className="itemlist__price">
                  {mercari.price ? (
                    <>
                      <span className="itemlist__yen-symbol">￥</span>
                      {`${mercari.price.toLocaleString()}`}
                    </>
                  ) : (
                    '価格未設定'
                  )}
                </p>
                <p className="itemlist__status">
                  {mercari.status
                    ? `商品の状態: ${mercari.status}`
                    : '商品の状態: 不明'}
                </p>
              </div>
              <a
                href={mercari.url}
                className="itemlist__purchase-button"
                target="_blank"
                rel="noopener noreferrer"
              >
                メルカリで見る
              </a>
            </div>
            {/* <span className="itemlist__arrow">→</span> */}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="itemlist__container">
      {item && (
        <div className="itemlist__page-info">
          <img
            src={item.image}
            alt={`${item.name}`}
            className="itemlist__brand-icon"
          />
          <h2 className="itemlist__page-title">{item.name}</h2>
          {item.brand && (
            <p className="itemlist__brand-name">{item.brand.name}</p>
          )}
          {item.categories && item.categories.length > 0 && (
            <p className="itemlist__categories">
              {item.categories.map((category) => category.name).join(', ')}
            </p>
          )}
          <p className="itemlist__item-price">
            {item.price > 0 && `￥${item.price.toLocaleString()}`}
          </p>
          {item.url && (
            <a
              href={item.url}
              className="itemlist__brandurl"
              target="_blank"
              rel="noopener noreferrer"
            >
              公式サイト
            </a>
          )}
        </div>
      )}

      {/* タブの追加 */}
      <ul className="itemlist__tabs" role="tablist">
        <li className="itemlist__tab-item">
          <button
            className={`itemlist__tab-link ${activeTab === 'info' ? 'itemlist__tab-link--active' : ''}`}
            onClick={() => setActiveTab('info')}
          >
            商品情報
          </button>
        </li>
        <li className="itemlist__tab-item">
          <button
            className={`itemlist__tab-link ${activeTab === 'posts' ? 'itemlist__tab-link--active' : ''}`}
            onClick={() => setActiveTab('posts')}
          >
            着画
          </button>
        </li>
      </ul>

      <div className="itemlist__tab-content">
        {activeTab === 'info' && (
          <div className="itemlist__info-content">
            {/* Mercari URLが複数ある場合に全て表示 */}
            {mercariUrls.length > 0 ? (
              showMercariUrls(mercariUrls)
            ) : (
              <p>メルカリでの販売はありません。</p>
            )}
            <h3 className="itemlist__title">商品詳細</h3>
            {item?.comment?.length ? (
              <p>{item?.comment}</p>
            ) : (
              <p>コメントはありません</p>
            )}
            <a
              href={item?.url}
              target="_blank"
              rel="noopener noreferrer"
              className="itemlist__official-page-button"
            >
              商品を公式サイトで確認
            </a>
            {item?.mercari_search_url && (
              <a
                className="itemlist__official-page-button"
                href={item?.mercari_search_url}
              >
                メルカリでこの商品を検索
              </a>
            )}
            {/* <h3 className="itemlist__title">他の出品者の商品</h3>
            <p>新品&中古 7点を確認する</p>
            <p>￥120000より</p>
            <h3 className="itemlist__title">おすすめの商品</h3> */}
          </div>
        )}
        {activeTab === 'posts' && showPosts(posts)}
      </div>
    </div>
  );
};

export default ItemList;
