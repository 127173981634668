import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { LOGIN_PATH } from '../utils/Constant';
import API from '../utils/API/API';
import Loading from './Loading';

const SignupConfirm = () => {
  const { uidb64, token } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const verifySignup = async () => {
      try {
        await API()(`api/activate/${uidb64}/${token}/`);
        alert(
          'メールアドレスが確認されました。ログインページにリダイレクトします。',
        );
        navigate(LOGIN_PATH);
      } catch {
        alert('認証に失敗しました');
        navigate('/');
      } finally {
        setLoading(false);
      }
    };

    verifySignup();
  }, [uidb64, token, navigate]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="signup-confirm-container">
      <h2 className="signup-confirm-title">メールアドレスを確認しています…</h2>
    </div>
  );
};

export default SignupConfirm;
