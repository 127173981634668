import React, { useState, useEffect } from 'react';
import AuthAPI from '../utils/API/AuthAPI';
import { Link, useLocation } from 'react-router-dom';
import '../css/MyPage.css';
import Loading from './Loading';
import { DEFAULT_MODELUSER_ICON } from '../utils/Constant';

interface User {
  username: string;
  email: string;
}

interface Skelton {
  name: string;
}

interface Model {
  bio: string;
  height: number;
  skelton: Skelton | null;
  icon: string | null;
  instagram: string;
}

interface ApiResponse {
  user: User;
  model: Model | null;
}

const MyPage: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [model, setModel] = useState<Model | null>(null);
  const [activeSection, setActiveSection] = useState('profile');
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    AuthAPI()
      .get('api/mypage/')
      .then((response) => {
        const data: ApiResponse = response.data;
        setUser(data.user);
        setModel(data.model);
        setLoading(false);
      })
      .catch((error) => {
        console.error('There was an error fetching the user data!', error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (location.hash) {
      setActiveSection(location.hash.substring(1));
    }
  }, [location]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="settings-container">
      <div className="settings-content">
        {model && activeSection === 'profile' && (
          <div id="profile" className="content-section">
            <div className="profile-page-container">
              <img
                className="profile-page-icon"
                src={model.icon || DEFAULT_MODELUSER_ICON}
                alt={`${user?.username}'s icon`}
              />
              <div className="profile-page-info">
                <h2>{user?.username}</h2>
                <p>{model.bio}</p>
                <p>Height: {model.height}cm</p>
                <p>Skelton: {model.skelton?.name}</p>
              </div>
              <div className="profile-page-buttons">
                <Link to="/edit-my-page" className="edit-profile-button">
                  Edit Profile
                </Link>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://www.instagram.com/${model.instagram}`}
                  className="instagram-button"
                >
                  Instagram
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyPage;
