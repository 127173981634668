import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../css/EditMyPage.css';
import AuthAPI from '../utils/API/AuthAPI';
import { DEFAULT_MODELUSER_ICON } from '../utils/Constant';

const EditMyPage: React.FC = () => {
  const [formData, setFormData] = useState({
    instagram: '',
    bio: '',
    height: '',
    skelton: '',
    icon: null as File | null,
  });
  const [profileImage, setProfileImage] = useState<string>(
    DEFAULT_MODELUSER_ICON,
  );
  const [username, setUsername] = useState<string>('');

  useEffect(() => {
    AuthAPI()
      .get('api/mypage/')
      .then((response) => {
        const data = response.data.model;
        setFormData({
          instagram: data.instagram || '',
          bio: data.bio || '',
          height: data.height.toString() || '',
          skelton: data.skelton?.id || '',
          icon: null,
        });
        setProfileImage(data.icon || DEFAULT_MODELUSER_ICON);
        setUsername(response.data.user.username);
      })
      .catch((error) => {
        console.error('There was an error fetching the user data!', error);
      });
  }, []);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    setFormData({
      ...formData,
      icon: file,
    });
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append('instagram', formData.instagram);
    formDataToSend.append('bio', formData.bio);
    formDataToSend.append('height', formData.height);
    formDataToSend.append('skelton', formData.skelton);
    if (formData.icon) {
      formDataToSend.append('icon', formData.icon);
    }

    AuthAPI()
      .patch('api/update-modeluser-profile/', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => {})
      .catch((error) => {
        console.error('There was an error updating the profile!', error);
      });
  };

  return (
    <div className="edit-profile-container">
      <h2 className="edit-profile-title">プロフィールを編集</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="icon" className="bold-label">
            プロフィール画像:
          </label>
          <div className="profile-input-container">
            <img
              src={profileImage}
              alt="Current Profile"
              className="current-profile-image"
            />
            <span className="username-display">{username}</span>
            <label htmlFor="icon" className="custom-file-upload">
              写真を変更
              <input
                type="file"
                id="icon"
                name="icon"
                onChange={handleFileChange}
              />
            </label>
          </div>
        </div>
        <div>
          <label htmlFor="instagram" className="bold-label">
            Instagram:
          </label>
          <input
            type="text"
            id="instagram"
            name="instagram"
            value={formData.instagram}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="bio" className="bold-label">
            自己紹介:
          </label>
          <textarea
            id="bio"
            name="bio"
            value={formData.bio}
            onChange={handleChange}
          ></textarea>
        </div>
        <div>
          <label htmlFor="height" className="bold-label">
            身長:
          </label>
          <input
            type="text"
            id="height"
            name="height"
            value={formData.height}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="skelton" className="bold-label">
            骨格:
          </label>
          <select
            id="skelton"
            name="skelton"
            value={formData.skelton}
            onChange={handleChange}
          >
            <option value="0">------</option>
            <option value="1">Wave</option>{' '}
            {/* TODO 手打ちじゃなくてAPIから取得したデータを並べる */}
            <option value="2">Natural</option>
            <option value="3">Straight</option>
          </select>
        </div>
        <button type="submit" className="submit-button">
          保存
        </button>
      </form>
      <Link to="/my-page" className="back-link">
        Back
      </Link>
    </div>
  );
};

export default EditMyPage;
