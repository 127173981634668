import React, { useState } from 'react';
import '../css/Signup.css';
import API from '../utils/API/API';
import { AxiosError } from 'axios';

const Signup: React.FC = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [error, setError] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [inputDisabled, setInputDisabled] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setInputDisabled(true);
    try {
      const response = await API().post('api/signup/', {
        username: username,
        email: email,
        password: password,
        password_confirmation: passwordConfirmation,
      });
      if (response.data.detail) {
        setEmailSent(true);
      } else {
        setError('Invalid credentials');
      }
    } catch (err) {
      setInputDisabled(false);
      if (err instanceof AxiosError && err.response && err.response.data) {
        const data = err.response.data as {
          detail?: { username?: string[]; email?: string[] };
        };
        const detail = data.detail;
        if (detail) {
          if (detail.username) {
            setError(`Username error: ${detail.username[0]}`);
          } else if (detail.email) {
            setError(`Email error: ${detail.email[0]}`);
          } else {
            setError('An unknown error occurred.');
          }
        } else {
          setError('An error occurred during signup.');
        }
      } else {
        setError('An error occurred during signup.');
      }
    }
  };

  const handleResendSubmit = async () => {
    setError('');
    try {
      const response = await API().post('api/signup/resend/', {
        username: username,
        email: email,
        password: password,
        password_confirmation: passwordConfirmation,
      });
      if (response.data.detail) {
        setEmailSent(true);
      } else {
        setError('Invalid credentials');
      }
    } catch (err) {
      if (err instanceof AxiosError && err.response && err.response.data) {
        const data = err.response.data as {
          detail?: { username?: string[]; email?: string[] };
        };
        const detail = data.detail;
        if (detail) {
          if (detail.username) {
            setError(`Username error: ${detail.username[0]}`);
          } else if (detail.email) {
            setError(`Email error: ${detail.email[0]}`);
          } else {
            setError('An unknown error occurred.');
          }
        } else {
          setError('An error occurred during signup.');
        }
      } else {
        setError('An error occurred during signup.');
      }
    }
  };

  return (
    <div className="signup">
      <h1 className="signup__title">Sign Up</h1>
      <p className="signup__explanation">
        アカウント登録することで、お気に入りの投稿を保存することができます。
      </p>
      {error && <p className="signup__error">{error}</p>}
      {!emailSent ? (
        <form onSubmit={handleSubmit} className="signup__form">
          <div className="signup__form-group">
            <label htmlFor="username" className="signup__label">
              Username
            </label>
            <input
              type="text"
              id="username"
              name="username"
              className="signup__input"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              disabled={inputDisabled}
            />
          </div>
          <div className="signup__form-group">
            <label htmlFor="email" className="signup__label">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="signup__input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={inputDisabled}
            />
          </div>
          <div className="signup__form-group">
            <label htmlFor="password" className="signup__label">
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              className="signup__input"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={inputDisabled}
            />
          </div>
          <div className="signup__form-group">
            <label htmlFor="passwordConfirmation" className="signup__label">
              Confirm Password
            </label>
            <input
              type="password"
              id="passwordConfirmation"
              name="passwordConfirmation"
              className="signup__input"
              value={passwordConfirmation}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
              disabled={inputDisabled}
            />
          </div>
          <button type="submit" className="signup__btn signup__btn--custom">
            Sign Up
          </button>
        </form>
      ) : (
        <div className="signup__links">
          <p>
            <b>{email}</b>
            <br />
            に認証メールを送信しました。
          </p>
          <p>
            メールが届かない場合：
            <button
              className="signup__link-button"
              onClick={handleResendSubmit}
            >
              メールを再送する
            </button>
          </p>
        </div>
      )}
    </div>
  );
};

export default Signup;
