export const BASE_URL: string =
  'https://designgo-cloudrun-zkfoyszk7q-uc.a.run.app/';
// export const BASE_URL: string = 'http://127.0.0.1:8000/';
export const ACCESS_TOKEN_KEY = 'access_token';
export const REFRESH_TOKEN_KEY = 'refresh_token';
export const LOGIN_PATH = '/login';
export const DEFAULT_MODELUSER_ICON = '/images/default_modeluser_icon.webp';
export const DEFAULT_BRAND_ICON = '/images/default_brand_icon.jpeg';
export const DEFAULT_CATEGORY_ICON = '/images/default_category_icon.avif';
export const MAX_IMAGE_COUNT = 5;
