import axios from 'axios';
import { BASE_URL } from '../Constant';

const API = () => {
  return axios.create({
    baseURL: BASE_URL,
  });
};

export default API;
