import React, { useEffect, useState } from 'react';
import OpenAuthAPI from '../utils/API/OpenAuthAPI';
import AuthAPI from '../utils/API/AuthAPI';
import '../css/StaffManageItem.css';
import heic2any from 'heic2any';

interface Category {
  id: number;
  name: string;
  children: Category[];
  parent_id: number | null;
  ancestors: string[];
}

interface Brand {
  id: number;
  name: string;
  url: string;
  icon: string | null;
}

function StaffManageItemAdd() {
  const [itemName, setItemName] = useState<string>('');
  const [newUrl, setNewUrl] = useState<string>('');
  const [comment, setComment] = useState<string>('');
  const [price, setPrice] = useState<number | null>(null);
  const [image, setImage] = useState<File | null>(null);
  const [imagePreviews, setImagePreviews] = useState<string[]>([]);
  const [successFetch, setSuccessFetch] = useState<boolean>(true);
  const [categories, setCategories] = useState<Category[]>([]);
  const [filteredCategories, setFilteredCategories] = useState<Category[]>([]);
  const [categorySearchTerm, setCategorySearchTerm] = useState<string>(''); // カテゴリ検索用
  const [selectedCategories, setSelectedCategories] = useState<number[]>([]); // 選択されたカテゴリのIDを保存
  const [brands, setBrands] = useState<Brand[]>([]);
  const [filteredBrands, setFilteredBrands] = useState<Brand[]>([]);
  const [brandSearchTerm, setBrandSearchTerm] = useState<string>(''); // ブランド検索用
  const [selectedBrand, setSelectedBrand] = useState<number | null>(null);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // 使用するように変更
  const [categoryFields, setCategoryFields] = useState<number[]>([0]); // 複数カテゴリ選択用のフィールド管理

  // カテゴリとブランドのデータ取得
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await OpenAuthAPI().get('/api/category-leaves/');
        setCategories(response.data.categories);
        setFilteredCategories(response.data.categories);
      } catch (error) {
        console.log('Failed to fetch categories:', error);
      }
    };
    fetchCategories();

    const fetchBrands = async () => {
      try {
        const response = await OpenAuthAPI().get('/api/brands/');
        setBrands(response.data.results);
        setFilteredBrands(response.data.results);
      } catch (error) {
        console.log('Failed to fetch Brands:', error);
      }
    };
    fetchBrands();
  }, []);

  // カテゴリの検索処理
  const handleCategorySearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value.toLowerCase();
    setCategorySearchTerm(searchValue);

    const filtered = categories.filter((category) =>
      category.name.toLowerCase().includes(searchValue),
    );
    setFilteredCategories(filtered);
  };

  // カテゴリ追加
  const addCategoryField = () => {
    setCategoryFields([...categoryFields, categoryFields.length]); // 新しいカテゴリフィールドを追加
  };

  // カテゴリの選択処理
  const handleCategoryChange = (
    index: number,
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const updatedSelectedCategories = [...selectedCategories];
    updatedSelectedCategories[index] = Number(event.target.value);
    setSelectedCategories(updatedSelectedCategories);
  };

  // ブランドの検索処理
  const handleBrandSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value.toLowerCase();
    setBrandSearchTerm(searchValue);

    const filtered = brands.filter((brand) =>
      brand.name.toLowerCase().includes(searchValue),
    );
    setFilteredBrands(filtered);
  };

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setErrorMessage(null); // 新しい画像選択時にエラーメッセージをクリア

      if (file.type === 'image/heic') {
        try {
          const convertedBlob = await heic2any({
            blob: file,
            toType: 'image/jpeg', // JPEG形式に変換
          });
          const reader = new FileReader();
          reader.onloadend = () => {
            setImagePreviews((prevPreviews) => [
              ...prevPreviews,
              reader.result as string,
            ]);
          };
          reader.readAsDataURL(convertedBlob as Blob);
          const convertedFile = new File(
            [convertedBlob],
            file.name.replace(/\.heic$/i, '.jpg'),
            {
              type: 'image/jpeg',
              lastModified: new Date().getTime(),
            },
          );
          setImage(convertedFile);
        } catch (error) {
          console.error('HEICファイルの変換に失敗しました: ', error);
          setErrorMessage('この画像はアップロードできません');
        }
      } else {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreviews((prevPreviews) => [
            ...prevPreviews,
            reader.result as string,
          ]);
        };
        reader.onerror = () => {
          setErrorMessage('この画像はアップロードできません');
        };
        reader.readAsDataURL(file);
        setImage(file);
      }
    }
  };

  // バリデーション
  const validateForm = () => {
    const formErrors: { [key: string]: string } = {};

    if (!itemName) {
      formErrors['itemName'] = 'アイテム名は必須です。';
    }

    if (!image) {
      formErrors['image'] = '画像は必須です。';
    }

    if (selectedCategories.length === 0) {
      formErrors['categories'] = 'カテゴリを少なくとも1つ選択してください。';
    }

    setErrors(formErrors);

    return Object.keys(formErrors).length === 0;
  };

  // アイテム追加処理
  const handleAddItem = async () => {
    if (!validateForm()) {
      console.log('Validation failed');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('name', itemName);
      formData.append('url', newUrl);
      formData.append('comment', comment);
      formData.append('price', price !== null ? String(price) : '');
      if (image) {
        formData.append('image', image);
      }

      selectedCategories.forEach((categoryId) => {
        formData.append('categories', String(categoryId));
      });

      if (selectedBrand !== null) {
        formData.append('brand', String(selectedBrand));
      }

      await AuthAPI().post(`/api/items/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setSuccessFetch(true);
      // window.location.reload();
    } catch (error) {
      console.log('Failed to add item:', error);
      setSuccessFetch(false);
    }
  };

  const renderCategoryOptions = (categoryList: Category[]) => {
    return categoryList.map((category) => (
      <option key={category.id} value={category.id}>
        {category.ancestors.join(' > ')} {/* パンくずリスト形式で表示 */}
      </option>
    ));
  };

  const renderBrandOptions = (brandList: Brand[]) => {
    return brandList.map((brand) => (
      <option key={brand.id} value={brand.id}>
        {brand.name}
      </option>
    ));
  };

  return (
    <div className="staff-manage-item">
      <h2 className="staff-manage-item__title">アイテム登録</h2>
      <div className="staff-manage-item__form">
        <h3>基本情報</h3>
        <input
          type="text"
          value={itemName}
          onChange={(e) => setItemName(e.target.value)}
          placeholder="アイテム名を入力(必須)"
          className="staff-manage-item__input"
        />
        {errors.itemName && <p className="error">{errors.itemName}</p>}
        <input
          type="text"
          value={newUrl}
          onChange={(e) => setNewUrl(e.target.value)}
          placeholder="商品の公式URLを入力(任意)"
          className="staff-manage-item__input"
        />
        <input
          type="number"
          value={price !== null ? price : ''}
          onChange={(e) =>
            setPrice(e.target.value ? parseInt(e.target.value) : null)
          }
          placeholder="価格を入力 (任意)"
          className="staff-manage-item__input"
        />
        <input
          type="text"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder="コメントを入力(任意)"
          className="staff-manage-item__input"
        />
        {/* ブランド検索 */}
        <div className="staff-manage-item__form">
          <h3>ブランドを選択</h3>
          <input
            type="text"
            value={brandSearchTerm}
            onChange={handleBrandSearch}
            placeholder="ブランド名を検索"
            className="staff-manage-item__input"
          />
          <select
            onChange={(e) => setSelectedBrand(Number(e.target.value))}
            className="staff-manage-item__select"
            value={selectedBrand || ''}
          >
            <option value="">---</option>
            {renderBrandOptions(filteredBrands)}
          </select>
        </div>
        {/* カテゴリ検索 */}
        <div className="staff-manage-item__form">
          <h3>カテゴリを選択</h3>
          <input
            type="text"
            value={categorySearchTerm}
            onChange={handleCategorySearch}
            placeholder="カテゴリ名を検索"
            className="staff-manage-item__input"
          />
          {categoryFields.map((field, index) => (
            <div key={index}>
              <select
                onChange={(e) => handleCategoryChange(index, e)}
                className="staff-manage-item__select"
                value={selectedCategories[index] || ''}
              >
                <option value="">---</option>
                {renderCategoryOptions(filteredCategories)}
              </select>
            </div>
          ))}
          <button
            onClick={addCategoryField}
            className="staff-manage-item__click"
          >
            カテゴリーをもう１つ追加
          </button>
        </div>
        <h3>画像を追加(必須)</h3>
        <input
          type="file"
          onChange={handleImageChange}
          placeholder="画像を選択"
          className="staff-manage-item__input"
        />
        {/* プレビュー表示時にaltを設定 */}
        <div className="image-previews">
          {imagePreviews.map((preview, index) => (
            <img key={index} src={preview} alt={`Preview ${index}`} />
          ))}
        </div>
        {errors.image && <p className="error">{errors.image}</p>}
        {errorMessage && <p className="error">{errorMessage}</p>}{' '}
        {/* エラーメッセージの表示 */}
        <button onClick={handleAddItem} className="staff-manage-post__button">
          商品を追加
        </button>
        {!successFetch && (
          <div className="staff-manage-item__error">
            商品の追加に失敗しました。
          </div>
        )}
      </div>
    </div>
  );
}

export default StaffManageItemAdd;
