import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import API from '../utils/API/API';
import '../css/BrandSearch.css';
import BackArrowIcon from '../assets/icon/backarrow';

interface Brand {
  id: number;
  name: string;
  url: string;
  icon: string | null;
}

const BrandSearch: React.FC = () => {
  const [brands, setBrands] = useState<Brand[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filteredBrands, setFilteredBrands] = useState<Brand[]>([]);

  useEffect(() => {
    API()
      .get('api/brands/')
      .then((response) => {
        const sortedBrands = response.data.results.sort((a: Brand, b: Brand) =>
          a.name.localeCompare(b.name, 'en', { sensitivity: 'base' }),
        );
        setBrands(sortedBrands);
        setFilteredBrands(sortedBrands);
      })
      .catch((error) => console.error('Failed to fetch brands:', error));
  }, []);

  useEffect(() => {
    // 検索キーワードに基づいてブランドをフィルタリング
    const filtered = brands.filter((brand) =>
      brand.name.toLowerCase().includes(searchTerm.toLowerCase()),
    );
    setFilteredBrands(filtered);
  }, [searchTerm, brands]);

  const handleBackClick = () => {
    window.history.back();
  };

  return (
    <div className="brand-search__container">
      <button
        onClick={handleBackClick}
        className="category-search__back-button"
      >
        <BackArrowIcon />
      </button>
      <h2 className="brand-search__title">ブランド</h2>
      <input
        type="text"
        placeholder="ブランドをさがす"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="brand-search__input"
      />

      {filteredBrands.map((brand) => (
        <div key={brand.id} className="brand-search__item">
          <Link to={`/brand/${brand.id}`} className="brand-search__link">
            {brand.name}
          </Link>
        </div>
      ))}
    </div>
  );
};

export default BrandSearch;
