import React from 'react';
import '../css/Post.css';
import '../css/PostSlider.css';

interface Category {
  id: number;
  name: string;
}

interface Brand {
  id: number;
  name: string;
  url: string;
  icon: string | null;
}

interface Item {
  id: number;
  name: string;
  price: number;
  image: string;
  url: string;
  brand: Brand | null;
  categories: Category[];
}

const ItemSlider: React.FC<{ items: Item[] }> = ({ items }) => {
  return (
    <div
      className="postslider__container"
      style={{ overflowX: 'scroll', whiteSpace: 'nowrap', padding: '10px' }}
    >
      {items.map((item) => (
        <div key={item.id} className="postslider__item">
          <a
            href={`/itemlist/${item.id}`}
            className="postslider__item-image-container"
          >
            <img
              src={item.image}
              alt={item.name}
              className="postslider__item-image"
            />
          </a>
          <a href={`/itemlist/${item.id}`} className="postslider__item-details">
            {item.brand && <p>{item.brand!.name}</p>}
            <p>{item.name}</p>
            <p>{item.price > 0 ? `￥${item.price.toLocaleString()}` : null}</p>
          </a>
        </div>
      ))}
    </div>
  );
};

export default ItemSlider;
