import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../css/PrivacySection.css';
import AuthAPI from '../utils/API/AuthAPI';

const ChangeEmail: React.FC = () => {
  const [email, setEmail] = useState('');
  const [currentEmail, setCurrentEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    AuthAPI()
      .get('api/mypage/')
      .then((response) => {
        setEmail(response.data.user.email);
        setCurrentEmail(response.data.user.email);
      })
      .catch((error) => console.error('failed to fetch /api/mypage/', error));
  }, []);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (email === currentEmail) {
      setError('New email cannot be the same as the current email');
      setMessage('');
      return;
    }

    AuthAPI()
      .post('api/user/update/email/', {
        new_email: email,
      })
      .then(() => {
        setMessage('Email changed successfully');
        setError('');
      })
      .catch(() => {
        setMessage('');
        setError('Failed to send activation email');
      });

    setMessage(`Sent activation email to ${email}`);
  };

  return (
    <div className="privacy-section privacy-section--email">
      <h1 className="privacy-section__title">メールアドレスを変更する</h1>
      {message && <p className="privacy-section__message">{message}</p>}
      {error && <p className="privacy-section__error">{error}</p>}
      <form onSubmit={handleSubmit} className="privacy-section__form">
        <div className="privacy-section__form-group">
          <label htmlFor="email" className="privacy-section__label">
            新しいメールアドレス
          </label>
          <input
            type="email"
            id="new_email"
            className="privacy-section__input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="privacy-section__submit-button">
          保存
        </button>
      </form>
      <Link to="/privacy" className="privacy-section__back-link">
        Back
      </Link>
    </div>
  );
};

export default ChangeEmail;
