import { useAuth } from '../utils/AuthContext';
import { Link } from 'react-router-dom';
import '../css/SideBar.css';
import { useSideBar } from '../utils/SideBarContext';

const SideBar: React.FC = () => {
  const { isAuthenticated, isModelUser, isStaffUser } = useAuth();
  const { isSideBarOpen, closeSideBar } = useSideBar();

  return (
    <div className="app">
      <div
        className={`sidebar__content ${isSideBarOpen ? 'sidebar__content--open' : ''}`}
      >
        {isAuthenticated ? (
          <>
            <Link
              className="sidebar__link"
              to="/favorite"
              onClick={closeSideBar}
            >
              お気に入り
            </Link>
            {isModelUser && (
              <>
                <Link
                  className="sidebar__link"
                  to="/my-page"
                  onClick={closeSideBar}
                >
                  マイページ
                </Link>
                <Link
                  className="sidebar__link"
                  to="/create/post"
                  onClick={closeSideBar}
                >
                  新規投稿
                </Link>
              </>
            )}
            {isStaffUser && (
              <>
                <Link
                  className="sidebar__link"
                  to="/staff"
                  onClick={closeSideBar}
                >
                  管理ページ
                </Link>
              </>
            )}
            <Link
              className="sidebar__link"
              to="/privacy"
              onClick={closeSideBar}
            >
              アカウント情報
            </Link>
            <Link className="sidebar__link" to="/logout" onClick={closeSideBar}>
              ログアウト
            </Link>
          </>
        ) : (
          <>
            <Link className="sidebar__link" to="/login" onClick={closeSideBar}>
              ログイン
            </Link>
          </>
        )}
      </div>
      <div
        className={`sidebar__overlay ${isSideBarOpen ? 'sidebar__overlay--visible' : ''}`}
        onClick={closeSideBar}
      ></div>
    </div>
  );
};

export default SideBar;
