import React from 'react';
import '../css/NotFound.css';

const NotFound: React.FC = () => {
  return (
    <div className="not-found__container">
      <h1 className="not-found__title">404 Not Found</h1>
      <p className="not-found__text">
        The page you are looking for does not exist.
      </p>
      <a href="/" className="not-found__link">
        Go back to Home
      </a>
    </div>
  );
};

export default NotFound;
