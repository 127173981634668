import { useEffect, useState } from 'react';
import API from '../utils/API/API';
import { useParams, useNavigate } from 'react-router-dom';
import '../css/ChangeEmailConfirm.css';
import Loading from './Loading';

const ChangeEmailConfirm = () => {
  const { uidb64, token } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        await API().get(`api/user/update/email/confirm/${uidb64}/${token}/`);
        navigate('/privacy');
      } catch {
        alert('認証に失敗しました');
        navigate('/');
      } finally {
        setLoading(false);
      }
    };
    verifyEmail();
  }, [uidb64, token, navigate]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="email-confirm-container">
      <h1 className="email-confirm-title">メールアドレスを確認しています…</h1>
      <p className="email-confirm-text">
        少々お待ちください。メールアドレスの確認が完了したら、ログインページに移動します。
      </p>
    </div>
  );
};

export default ChangeEmailConfirm;
