import React from 'react';
import '../css/AboutUs.css';

const Contact: React.FC = () => {
  return (
    <div className="contact">
      <h1 className="contact__title">お問い合わせ</h1>
      <p className="contact__description">
        Momelaに関するお問い合わせ、ご意見、情報のご提供などは下記メールアドレスへお願いいたします。
      </p>
      <h2 className="contact__section-title">メールアドレス</h2>
      <p className="contact__section-description">8bingo.official@gmail.com</p>
    </div>
  );
};

export default Contact;
