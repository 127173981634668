import React, { useEffect, useState } from 'react';
import '../css/PrivacySection.css';
import AuthAPI from '../utils/API/AuthAPI';

const PrivacySection: React.FC = () => {
  const [username, setUsername] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  const fetchUserData = async () => {
    await AuthAPI()
      .get('api/mypage/')
      .then((response) => {
        setUsername(response.data.user.username);
        setEmail(response.data.user.email);
      })
      .catch((error) => {
        console.error('Error fetching user data', error);
      });
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <div id="privacy" className="privacy-section">
      <h1 className="privacy-section__title">アカウント情報</h1>
      <p className="privacy-section__description">
        ログインの際に必要な情報を確認できます。
      </p>
      <div className="privacy-section__item">
        <h2 className="privacy-section__item-title">ユーザー名</h2>
        <p className="privacy-section__item-content">{username}</p>
        <a href="/update/username" className="privacy-section__link">
          ユーザー名を変更する
        </a>
      </div>
      <div className="privacy-section__item">
        <h2 className="privacy-section__item-title">Email</h2>
        <p className="privacy-section__item-content">{email}</p>
        <a href="/update/email" className="privacy-section__link">
          Emailを変更する
        </a>
      </div>
      <div className="privacy-section__item">
        <h2 className="privacy-section__item-title">Password</h2>
        <p className="privacy-section__item-content">表示できません</p>
        <a href="/update/password" className="privacy-section__link">
          パスワードを変更する
        </a>
      </div>
    </div>
  );
};

export default PrivacySection;
