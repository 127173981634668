import API from './API/API';
const ACCESS_TOKEN_KEY = 'access_token';
const REFRESH_TOKEN_KEY = 'refresh_token';

export const getAccessToken = (): string | null => {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
};

export const setAccessToken = (token: string): void => {
  localStorage.setItem(ACCESS_TOKEN_KEY, token);
};

export const clearAccessToken = (): void => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
};

export const getRefreshToken = (): string | null => {
  return localStorage.getItem(REFRESH_TOKEN_KEY);
};

export const setRefreshToken = (token: string): void => {
  localStorage.setItem(REFRESH_TOKEN_KEY, token);
};

export const clearRefreshToken = (): void => {
  localStorage.removeItem(REFRESH_TOKEN_KEY);
};

export const clearTokens = (): void => {
  clearAccessToken();
  clearRefreshToken();
};

export const refreshAccessToken = async () => {
  const refreshToken = getRefreshToken();
  if (!refreshToken) return null;
  return await API()
    .post('api/token/refresh/', {
      refresh: refreshToken,
    })
    .then((res) => {
      setAccessToken(res.data.access);
      setRefreshToken(res.data.refresh);
      return res.data.access;
    })
    .catch((error) => {
      console.error('Error refreshing access token:', error);
      clearTokens();
      return null;
    });
};

export const verifyAccessToken = async () => {
  const token = getAccessToken();
  if (token) {
    return await API()
      .post('api/token/verify/', {
        token: token,
      })
      .then(() => true)
      .catch(() => false);
  } else {
    return false;
  }
};
