import React from 'react';
import '../css/Footer.css';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="footer__container">
        <p className="footer__links">
          <a href="/about-us" className="footer__link">
            About Us
          </a>
          <a href="/privacy-policy" className="footer__link">
            Privacy Policy
          </a>
          <a href="/contact" className="footer__link">
            Contact
          </a>
        </p>
        <p className="footer__text">&copy; 2024 8BINGO. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
