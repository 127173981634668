import React, { useState } from 'react';
import { useSearch } from '../utils/SearchContext';
import '../css/SearchModal.css';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const SearchUniversal: React.FC = () => {
  const { isSearchOpen, toggleSearch } = useSearch();
  const [queryText, setQueryText] = useState<string>('');
  const navigate = useNavigate();

  const resetSearch = () => {
    setQueryText('');
    toggleSearch();
  };

  const handleSearch = async () => {
    if (queryText) {
      navigate(`/search/${queryText}`);
      resetSearch();
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleCategoryClick = () => {
    navigate('/categories');
    resetSearch();
  };

  const handleBrandClick = () => {
    navigate('/brands');
    resetSearch();
  };

  if (!isSearchOpen) return null;

  return (
    <div className="search-modal">
      <div className="search-modal__header">
        <div className="search-modal__input-container">
          <button onClick={resetSearch} className="search-modal__close-button">
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <button
            onClick={handleSearch}
            className="search-modal__search-button"
          >
            <FontAwesomeIcon icon={faSearch} />
          </button>
          <input
            type="text"
            value={queryText}
            onChange={(e) => setQueryText(e.target.value)}
            placeholder="なにをお探しですか？"
            onKeyDown={handleKeyDown}
            className="search-modal__input"
          />
        </div>
        <div className="search-modal__category-brand-container">
          <div className="search-modal__quick-links">
            <button
              className="search-modal__quick-link"
              onClick={handleCategoryClick}
            >
              カテゴリーからさがす
            </button>
            <button
              className="search-modal__quick-link"
              onClick={handleBrandClick}
            >
              ブランドからさがす
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchUniversal;
