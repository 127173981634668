import React, { useEffect } from 'react';

import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MyPage from './components/MyPage';
import Favorite from './components/Favorite';
import Signup from './components/Signup';
import SignupConfirm from './components/SignupConfirm';
import Login from './components/Login';
import Home from './components/Home';
import Post from './components/Post';
import CategoryView from './components/Category';
import CategoryForCreatePost from './components/CategoryForCreatePost';
import CategorySearch from './components/CategorySearch';
import Brand from './components/Brand';
import BrandForCreatePost from './components/BrandForCreatePost';
import BrandList from './components/BrandList';
import BrandSearch from './components/BrandSearch';
import ItemList from './components/Itemlist';
import ProfileView from './components/Profile';
import Logout from './components/Logout';
import EditMyPage from './components/EditMyPage';
import AboutUs from './components/AboutUs';
import PrivacyPolicy from './components/PrivacyPolicy';
import Disclaimer from './components/Disclaimer';
import Contact from './components/Contact';
import ChangeUserName from './components/ChangeUserName';
import ChangeEmail from './components/ChangeEmail';
import ChangeEmailConfirm from './components/ChangeEmailConfirm';
import ChangePassword from './components/ChangePassword';
import ResetPassword from './components/ResetPassword';
import ResetPasswordConfirm from './components/ResetPasswordConfirm';
import PrivacySection from './components/PrivacySection';
import {
  AuthenticatedRoute,
  ModelUserRoute,
  StaffUserRoute,
  ModelOrStaffUserRoute,
} from './utils/ProtectedRoute';
import { refreshAccessToken } from './utils/TokenManager';
import { AuthProvider } from './utils/AuthContext';
import Footer from './components/Footer';
import NotFound from './components/NotFound';
import Header from './components/Header';
import { SideBarProvider } from './utils/SideBarContext';
import SideBar from './components/SideBar';
import SearchUniversal from './components/SearchUniversal';
import { SearchProvider } from './utils/SearchContext';
import SearchResult from './components/SearchResult';
import CreatePost from './components/CreatePost';
import PreviewPost from './components/PreviewPost';
import StaffTop from './components/StaffTop';
import StaffPreviewActivatedPost from './components/StaffPreviewActivatedPost';
import StaffPreviewInActivatedPost from './components/StaffPreviewInActivatedPost';
import EditPost from './components/EditPost';
import StaffManagePost from './components/StaffManagePost';
import StaffManageUser from './components/StaffManageUser';
import StaffManageItemAdd from './components/StaffManageItemAdd';
import StaffManageItemMercari from './components/StaffManageItemMercari';

const App: React.FC = () => {
  return (
    <AuthProvider>
      <SideBarProvider>
        <SearchProvider>
          <Router>
            <AppContent />
          </Router>
        </SearchProvider>
      </SideBarProvider>
    </AuthProvider>
  );
};

const AppContent: React.FC = () => {
  useEffect(() => {
    // refresh token every 14 minutes
    const interval = setInterval(refreshAccessToken, 14 * 60 * 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <div className="app">
      <Header />
      <SideBar />
      <div className="app__container">
        <SearchUniversal />
        <br></br>
        {/* <SearchPost /> */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/posts/:id" element={<Post />} />
          <Route path="/search/:keyword" element={<SearchResult />} />
          <Route path="/category/:id" element={<CategoryView />} />
          <Route path="/categories" element={<CategorySearch />} />
          <Route path="/brand/:id" element={<Brand />} />
          <Route path="/brandlist" element={<BrandList />} />
          <Route path="/brands" element={<BrandSearch />} />
          <Route path="/itemlist/:id" element={<ItemList />} />
          <Route path="/profile/:username" element={<ProfileView />} />
          <Route
            path="/my-page"
            element={<ModelUserRoute element={<MyPage />} />}
          />
          <Route
            path="/edit-my-page"
            element={<ModelUserRoute element={<EditMyPage />} />}
          />
          <Route
            path="/favorite"
            element={<AuthenticatedRoute element={<Favorite />} />}
          />
          <Route path="/signup" element={<Signup />} />
          <Route path="/activate/:uidb64/:token" element={<SignupConfirm />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/logout"
            element={<AuthenticatedRoute element={<Logout />} />}
          />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/contact" element={<Contact />} />
          <Route
            path="/update/username"
            element={<AuthenticatedRoute element={<ChangeUserName />} />}
          />
          <Route
            path="/update/email"
            element={<AuthenticatedRoute element={<ChangeEmail />} />}
          />
          <Route
            path="/update/email/confirm/:uidb64/:token"
            element={<AuthenticatedRoute element={<ChangeEmailConfirm />} />}
          />
          <Route
            path="/update/password"
            element={<AuthenticatedRoute element={<ChangePassword />} />}
          />
          <Route path="/reset/password" element={<ResetPassword />} />
          <Route
            path="/reset/password/confirm/:uidb64/:token"
            element={<ResetPasswordConfirm />}
          />
          <Route
            path="/privacy"
            element={<AuthenticatedRoute element={<PrivacySection />} />}
          />
          <Route
            path="/create/post"
            element={<ModelUserRoute element={<CreatePost />} />}
          />
          <Route
            path="/create/post/categories"
            element={
              <ModelOrStaffUserRoute element={<CategoryForCreatePost />} />
            }
          />
          <Route
            path="/create/post/brands"
            element={<ModelOrStaffUserRoute element={<BrandForCreatePost />} />}
          />
          <Route
            path="/create/post/preview"
            element={<ModelUserRoute element={<PreviewPost />} />}
          />
          <Route
            path="/staff"
            element={<StaffUserRoute element={<StaffTop />} />}
          />
          <Route
            path="/staff/manage-post"
            element={<StaffUserRoute element={<StaffManagePost />} />}
          />
          <Route
            path="/staff/preview-post-active/:id/"
            element={<StaffUserRoute element={<StaffPreviewActivatedPost />} />}
          />
          <Route
            path="/staff/preview-post-inactive/:id/"
            element={
              <StaffUserRoute element={<StaffPreviewInActivatedPost />} />
            }
          />
          <Route
            path="/staff/edit-post/:id"
            element={<StaffUserRoute element={<EditPost />} />}
          />
          <Route
            path="/staff/manage-user"
            element={<StaffUserRoute element={<StaffManageUser />} />}
          />
          <Route
            path="/staff/manage-item-add"
            element={<StaffUserRoute element={<StaffManageItemAdd />} />}
          />
          <Route
            path="/staff/manage-item-mercari"
            element={<StaffUserRoute element={<StaffManageItemMercari />} />}
          />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

export default App;
